import "./EstateMoreClubInfo.less";
import * as React from "react";
import { Link } from "react-router-dom";

export class EstateMoreClubConditions extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="estate-more-club-info">
                <div className="main">
                    <h1>Regler og betingelser pr. 1. september 2014</h1>
                    <h4>Medlemskabet:</h4>
                    <p>1) Bestilling af medlemskab i Estate More Club skal ske online på estatekonference.dk/more eller ved henvendelse til vores kontor.</p>
                    <p>2) Bestilling er bindende, og ved bestilling accepteres gældende regler og betingelser.</p>
                    <p>3) Medlemskabet er personligt. Ejerskab og forpligtigelser er tilknyttet den bestillende virksomhed.</p>
                    <p>4) Medlemskab er gældende 12 måneder fra bestillingsdato, med mindre andet aftales. Fakturering sker umiddelbart efter bestilling.</p>
                    <p>5) Medlemskabet er først gældende når betaling er sket.</p>
                    <p>6) Ved medlemmets fratrædelse fra virksomheden, kan medlemskabet overdrages til en anden medarbejder i virksomheden. Ændring af medarbejdernavn skal meddeles Estate Media, før der kan ske tilmelding til arrangementer.</p>
                    <p>7) Indmeldelse i Estate More Club og brug af fordele og rabatter kan ikke kombineres med andre medlemsfordele eller rabatordninger, uden forudgående aftale med Estate Media.</p>
                    <p>8) Medlemskabet fornyes automatisk hver 12. måned. Afmeldelse af medlemskabet skal ske ved skriftlig henvendelse til Estate Media ifm. modtagelse af faktura for ny periode.</p>
                    <p>9) Såfremt et medlem er bekræftet som tilmeldt et arrangement i medlemsperioden, men dette arrangement flyttes til afholdelse efter udløb af medlemmets medlemsperiode, er deltagelse i arrangementet omfattet af medlemskabet.</p>
                    <p>10) Estate Media forbeholder sig ret til at ændre betingelser for medlemskabet af Estate More Club uden varsel. Ved ændring af betingelser vil disse være gældende fra førstkommende gentegning af medlemskabet. Estate Media forbeholder sig ret til at ændre priser, indhold og betingelser for de medlemskaber, der tilbydes nye medlemmer uden varsel.</p>
                    <h4>Tilmelding til arrangementer</h4>
                    <p>11) Tilmelding er bindende. Ved udeblivelse - uden at have meldt afbud senest dagen før - fra et arrangement faktureres et administrationsgebyr på kr. 1.000.</p>
                    <p>12) Medlemmer garanteres så vidt muligt plads på alle arrangementer, der er omfattet af det konkrete medlemskab. For Ejendomsdagene/Boligdagene gælder den garanterede plads kun for platinkortholdere, der dog skal bekræfte, at de ønsker at benytte sig af pladsen senest 3 måneder før afholdelse for at sikre sig pladsen.</p>
                    <p>13) Der ydes ikke godtgørelse til medlemmer, der ikke kan tilbydes deltagelse på arrangementer, der er fuldt bookede.</p>
                    <p>14) Ved evt. aflysning af eller ændringer i en dagskonference ydes ingen godtgørelse til medlemmer.</p>
                    <p>(Der tages forbehold for evt. trykfejl eller mangler)</p>
                    <div className="sign-up-link-container">
                        <p className="sign-up-link"><Link to={`/estate-more-club/tilmeld`}>Tilmeld</Link></p>
                        <p className="link-to-conditions"><Link to={`/estate-more-club/betingelser`}>Se praktisk information og betingelser for Estate More Club her</Link></p>
                    </div>
                </div>
                <div className="sidebar">
                    <h3>KUNDESERVICE</h3>
                    <p>For yderligere information eller spørgsmål omkring medlemskab i Estate More Club, er du velkommen til at kontakte Elisabeth Brahe på tlf. <a href="tel:004529160536">29 16 05 36</a> eller email <a href="mailto:info@estatemedia.dk">info@estatemedia.dk</a>.</p>

                    <p>Telefonen er åben mandag – fredag fra 09.00 - 17.00</p>

                    <div className="sign-up-link-container">
                        <p className="sign-up-link"><Link to={`/estate-more-club/tilmeld`}>Tilmeld</Link></p>
                        <p className="link-to-conditions"><Link to={`/estate-more-club/betingelser`}>Se praktisk information og betingelser for Estate More Club her</Link></p>
                    </div>
                </div>
            </div>
        )
    }
}