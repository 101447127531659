import "./EstateNews.less";
import * as React from "react";
import moment from "moment";
import * as _ from "lodash";

interface EstateNewsState {
    estateNews: any[]
}

export class EstateNews extends React.Component<{}, EstateNewsState> {
    public constructor(props: {}) {
        super(props);
        this.state = {
            estateNews: []
        }
    }
    public async componentDidMount() {
        const newsResponse = await fetch("https://estatemedia.dk/dk/wp-json/wp/v2/posts");
        const news = await newsResponse.json();
        this.setState({ estateNews: news.slice(0, 5) });
    }

    public render() {
        if (this.state.estateNews.length === 0) {
            return null;
        }
        return (
            <div className="estate-news">
                <p className="top-title">Seneste nyheder fra Estatemedia.dk</p>
                <div className="news-items">
                    {_.map(this.state.estateNews, singleNews => {
                        return (
                            <a className="singleNews" key={singleNews.id} href={singleNews.link} target="_blank">
                                <p className="date">{moment(singleNews.date).locale("da").format('D[.] MMMM YYYY H[:]mm')}</p>
                                <p className="title">{singleNews.title.rendered}</p>
                            </a>
                        )
                    })}
                </div>
            </div>
        )
    }
}