import "./Layout.less";
import * as React from "react";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import { Route, Switch } from "react-router-dom";
import { Front, AboutPage, EstateMoreClub, MyPage, ConferenceArchive, ConferenceOnDemand, ConferencePage, MyPageForgotPassword, ConferenceConditions } from "./pages/Index";

export class Layout extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="layout">
                <div className="content">
                    <Header />
                    <div className="main">
                        <Switch>
                            <Route exact path="/" component={Front} />
                            <Route path="/arrangementer/:searchQuery?" component={ConferenceArchive} />
                            <Route path="/ondemand/:searchQuery?" component={ConferenceOnDemand} />
                            <Route path="/arrangement/:conferenceId" component={ConferencePage} />
                            <Route path="/om-os" component={AboutPage} />
                            <Route path="/betingelser" component={ConferenceConditions} />
                            <Route path="/estate-more-club" component={EstateMoreClub} />
                            <Route path="/min-side/glemt-password" component={MyPageForgotPassword} />
                            <Route path="/min-side" component={MyPage} />
                            <Route component={Front} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}