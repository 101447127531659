import "./AddToCalendar.less";
import * as React from "react";
import AddToCalendar from 'react-add-to-calendar';
import moment from "moment";

interface AddToCalendarProps {
    title: string,
    description: string,
    location: string,
    startTime: string,
    endTime: string
}

export class AddToCalendarComponent extends React.Component<AddToCalendarProps, {}> {
    render() {
        const event = {
            description: this.props.description,
            endTime: moment(this.props.endTime).format('YYYYMMDDTHHmmssZ'),
            location: this.props.location,
            startTime: moment(this.props.startTime).format('YYYYMMDDTHHmmssZ'),
            title: this.props.title,
        }

        return (
            <div className="add-to-calendar">
                <AddToCalendar buttonLabel={"Tilføj til kalender"} event={event} buttonWrapperClass={"add-to-calendar-button"} dropdownClass={"add-to-calendar-dropdown"} />
            </div>
        );
    }
}