import * as React from "react";
import { Conference } from "src/models/Conference";

interface AboutProps {
    conference: Conference;
}

export class About extends React.Component<AboutProps, {}> {

    public render() {
        return (
            <>
                <div className="about" dangerouslySetInnerHTML={{ __html: this.props.conference.Description }}></div>
            </>
        )
    }
}