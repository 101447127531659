import "./Programme.less";
import * as React from "react";
import { Conference, ProgramItem } from "src/models/Conference";
import moment from "moment";
import * as _ from "lodash";
import Utils from "../../services/Utils";

interface ProgrammeProps {
    conference: Conference;
}

export class Programme extends React.Component<ProgrammeProps, {}> {
    private getByDay(programmeItems: ProgramItem[]) {
        const groups = _.groupBy(programmeItems, programmeItem => moment(programmeItem.DateTime).startOf('day'));
        return groups;
    }

    private getFormattedDate(programItem: ProgramItem){
        return Utils.toUpperFirstCase(moment(programItem.DateTime).locale("da").format('dddd [den] D[.] MMMM'));
    }

    public render() {
        document.title = 'Konference - ' + this.props.conference.Title + ' - Program';
        const programmeByDays = this.getByDay(this.props.conference.Programme);
        const isMultipleDayConference = _.size(programmeByDays) > 1;
        return (
            <div className="programme">
                {_.map(programmeByDays, programmeForDay => {
                    return (
                        <>
                            {isMultipleDayConference ? <h3>{this.getFormattedDate(programmeForDay[0])}</h3> : null}
                            {programmeForDay.map(programmeItem => {
                                    return (
                                        <div className="programme-item" key={programmeItem.DateTime}>
                                            <div className="time">
                                                <p>{moment(programmeItem.DateTime).format('HH:mm')}</p>
                                            </div>
                                            <div className="programme-item-info">
                                                <h4 className="title">{programmeItem.Title}</h4>
                                                <p className="description">{programmeItem.Description}</p>
                                                {programmeItem.Speakers.map(speaker => {
                                                    return (
                                                        <p key={speaker.Name + speaker.Title + speaker.CompanyName + speaker.Email} className="speaker">{speaker.Name}, {speaker.Title}, {speaker.CompanyName}</p>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>)
                })}

            </div>
        )
    }
}