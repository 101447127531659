import "./ConferenceArchive.less";
import * as React from "react";
import { CoverImage, ConferenceCardRow, Loader } from "../../components"
import { RouteComponentProps } from 'react-router-dom';
import Api from "../../services/Api";
import * as _ from "lodash";
import { Conference } from "../../models/Conference";
import { relativeTimeRounding } from "moment";

interface ConferenceArchiveState {
    conferences: Conference[];
}

export class ConferenceArchive extends React.Component<RouteComponentProps, ConferenceArchiveState> {
    private conferences: Api.Conferences;
    private lastSearchQuery: string;
    public constructor(props: RouteComponentProps) {
        super(props);

        this.state = { conferences: [] }
        this.conferences = new Api.Conferences();
    }

    public async componentDidMount() {
        const searchQuery = this.props.match.params["searchQuery"];
        this.getConferences(searchQuery);
    }

    public async componentDidUpdate() {
        const searchQuery = this.props.match.params["searchQuery"];
        if (this.lastSearchQuery === searchQuery) {
            return;
        }
        this.getConferences(searchQuery);

    }

    private async getConferences(searchQuery: string) {
        let conferences;
        if (searchQuery != null) {
            conferences = await this.conferences.searchConferences(searchQuery);
        } else {
            conferences = await this.conferences.getConferences();
        }

        this.lastSearchQuery = searchQuery;
        if(conferences){
            this.setState({ conferences: conferences });
        }
    }

    public render() {
        document.title = "Kommende arrangementer";
        const searchQuery = this.props.match.params["searchQuery"];
        const allConferences = this.state.conferences;
        const chunkConferences = _.chunk(allConferences, 3);

        if (this.state.conferences.length === 0) {
            return <Loader />
        }

        return (
            <div className="conference-archive">
                <CoverImage>
                    <div className="highligt-conference">
                        <p className="archive-header">{searchQuery == null ? "Kommende arrangementer" : "Dine søgeresultater"}</p>
                    </div>
                </CoverImage>
                {chunkConferences.map((threeConferences) => <ConferenceCardRow key={threeConferences[0].Id} conferences={threeConferences} />)}
            </div>
        )
    }
}