import "./Header.less";
import * as React from "react";
import { Link } from "react-router-dom";
import { Logo, ConferenceSearch, Hamburger } from "../";

interface HeaderState {
    searchIsOpen: boolean;
    burgerMenuIsOpen: boolean;
}

export class Header extends React.Component<{}, HeaderState> {

    public constructor(props: {}) {
        super(props);

        this.state = {
            searchIsOpen: false,
            burgerMenuIsOpen: false
        }
    }

    private toggleSearch() {
        this.setState({ searchIsOpen: !this.state.searchIsOpen });
    }

    private toggleBurgerMenu() {
        this.setState({ burgerMenuIsOpen: !this.state.burgerMenuIsOpen });
    }

    private onLinkClick(){
        this.setState({burgerMenuIsOpen: false});
    }

    public render() {

        //<p><Link onClick={() => {this.onLinkClick()}} to="/ondemand">Estate on demand</Link></p>

        return (
            <div className="header">
                <div className="header-content">
                    <div className="page-logo">
                        <Link to="/">
                            <Logo />
                        </Link>
                    </div>
                    <div className="burger-menu" onClick={this.toggleBurgerMenu.bind(this)}><Hamburger isActive={this.state.burgerMenuIsOpen} /></div>
                    <nav className={this.state.burgerMenuIsOpen === true ? "open" : null }>
                        <p><Link onClick={() => {this.onLinkClick()}} to="/arrangementer">Kommende arrangementer</Link></p>
                        <p><Link onClick={() => {this.onLinkClick()}} to="/ondemand">Estate on demand</Link></p> 
                        <p><Link onClick={() => {this.onLinkClick()}} to="/estate-more-club">More Club</Link></p>
                        <p><Link onClick={() => {this.onLinkClick()}} to="/min-side">Min side</Link></p>
                        <p><a href="https://estatemedia.dk" target="_blank">Nyheder</a></p>
                        <p><Link onClick={() => {this.onLinkClick()}} to="/om-os">Om os</Link></p>
                        <p onClick={this.toggleSearch.bind(this)}>Søg</p>
                    </nav>
                </div>
                <div className="search-box">
                    {this.state.searchIsOpen ? <ConferenceSearch /> : null}
                </div>

            </div>
        )
    }
}