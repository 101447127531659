import "./EstateMoreClubConfirmation.less";
import * as React from "react";

export class EstateMoreClubConfirmation extends React.Component<{}, {}> {

    public render() {
        return (
            <div className="estate-more-club-confirmation">
                <div className="main"><h1>VELKOMMEN I KLUBBEN</h1>
                    <p>Du har nu gratis deltagelse på alle Estate Medias dagskonferencer, events og seminarer i 12 måneder, samt en lang række andre fordele, alt efter om du er sølv, guld eller platin medlem.</p>

                    <p>Derudover vil du få tilsendt dit personlige eksemplar af Estate Magasin og adgang til en elektronisk pre-release, så du får nyhederne først.</p>

                    <p>Har du spørgsmål, er du velkommen til at kontakte mig på 51 53 83 50 eller <a href="mailto: linke@estatemedia.dk">linke@estatemedia.dk</a>, ellers ses vi blot ude til arrangementerne og endnu engang – velkommen i klubben!</p>

                    <p>Med venlig hilsen<br /><br />

                        Jonas Linke<br />
                        Kommerciel chef</p>
                    <div className="more-club-logo"></div>
                </div>
                <div className="sidebar">
                    <h3>KUNDESERVICE</h3>
                    <p>For yderligere information eller spørgsmål omkring medlemskab i Estate More Club, er du velkommen til at kontakte Elisabeth Brahe på tlf. <a href="tel:004529160536">29 16 05 36</a> eller email <a href="mailto:info@estatemedia.dk">info@estatemedia.dk</a>.</p>

                    <p>Telefonen er åben mandag – fredag fra 09.00 - 17.00</p>
                </div>
            </div>
        )
    }
}