import "./Front.less";
import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import { CoverImageFrontpage, ConferenceCardRow, Loader, AdvertisingBanner } from "../../components"
import Api from "../../services/Api";
import * as _ from "lodash";
import { Conference } from "../../models/Conference";
import Utils from "../../services/Utils";

interface FrontState {
    conferences: Conference[];
    shouldRedirect: boolean
}

export class Front extends React.Component<{}, FrontState> {
    private conferences: Api.Conferences;
    public constructor(props: {}) {
        super(props);

        this.state = { conferences: [], shouldRedirect: false }
        this.conferences = new Api.Conferences();
    }

    public async componentDidMount() {
        const conferences = await this.conferences.getConferences();
        if(conferences){
            this.setState({ conferences: conferences });
        }
    }

    private navigateToConference() {
        this.setState({ shouldRedirect: true });
    }

    public render() {
        if (this.state.conferences.length === 0) {
            return <Loader />;
        }
        
        const allConferences = this.state.conferences;
        const highligtConference = allConferences[0];
        const chunkConferences = _.chunk(allConferences.slice(1), 3);

        if(this.state.shouldRedirect === true) {
            return <Redirect to={"/arrangement/" + highligtConference.Id + "/om"} push={true} />
        }
        document.title = "Estate Konference - Konferencer for bygge- og ejendomsbranchen.";
        return (
            <div className="front">
                <div className="error">
                    <div className="errorHeading">Åh nej...</div>
                    <div>
                    Vi oplever desværre nogle fejl på vores konferenceside lige nu og beklager den ulejlighed det måtte give.
                <br/><br/>
                Hvis du har spørgsmål eller ønsker at tilmelde dig en konference, er du altid velkommen til at kontakte 
                Jonas på <a className="errorLink" href="mailto:linke@estatemedia.dk">linke@estatemedia.dk</a> eller på 51 53 83 50.
                </div>
                
                </div>
                <CoverImageFrontpage>
                    <div className="highligt-conference" onClick={() => { this.navigateToConference(); }}>
                        <div className="next-conference-headline">
                            <p>Næste konference</p>
                        </div>
                        <div className="conference-details">
                            <div className="conference-image" style={{ backgroundImage: "url(" + highligtConference.HeaderImage + ")" }}></div>
                            <div className="conference-info">

                                <div className="title-container">
                                    <h4 className="conference-title">{highligtConference.Title}</h4>
                                    <p className="conference-date"><p className="conference-date">{Utils.getLongDate(highligtConference.StartDateTime, highligtConference.EndDateTime)}</p></p>
                                </div>

                                <div className="conference-information-container">
                                    <div className="details-container">
                                        <div className="conference-info-details">
                                            <p className="details-title">{highligtConference.Type === 2 ? "Seminar" : "Konference"}</p>
                                            <p>{highligtConference.City}</p>
                                        </div>
                                        <div className="conference-info-details host-details">
                                            {highligtConference.HostLogo == null ? null : (
                                                <>
                                                    <p className="host-title">Vært:</p>
                                                    <img className="host-logo" src={highligtConference.HostLogo} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="call-to-action">
                                        <Link to={"/arrangement/" + highligtConference.Id + "/om"}>
                                            <p>Tilmeld</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </CoverImageFrontpage>
                {chunkConferences[0] == null ? null : <ConferenceCardRow key={chunkConferences[0].map(conference => conference.Id).toString()} conferences={chunkConferences[0]} />}
                <AdvertisingBanner />
                {chunkConferences.slice(1, 3).map((threeConferences) => <ConferenceCardRow key={threeConferences.map(conference => conference.Id).toString()} conferences={threeConferences} />)}
                <div className="more-conferences-link">
                    <p><Link to={"/ondemand"}>Se webinarer</Link>&nbsp;</p>&nbsp;
                    <p><Link to={"/arrangementer"}>Vis alle</Link></p>
                </div>

            </div>
        )
    }
}