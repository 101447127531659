import "./ConfirmationPage.less";
import * as React from "react";
import {Link} from "react-router-dom";
import { Conference } from "src/models/Conference";

interface ConfirmationPageProps {
    conference: Conference;
}

export class ConfirmationPage extends React.Component<ConfirmationPageProps, {}> {

    componentDidMount () {
        

        const script = document.createElement("script");
        if (this.props.conference.Id=="258") {
            script.src = "https://event.webinarjam.com/register/3v81ki8/embed-button";
        }
        if (this.props.conference.Id=="259") {
            script.src = "https://event.webinarjam.com/register/9vp60i5/embed-button";
        }
        if (this.props.conference.Id=="260") {
            script.src = "https://event.webinarjam.com/register/ry4olbvv/embed-button";
        }        
        //script.async = true;
    
        //document.body.appendChild(script);

        //alert('Du skal nu tilmelde dig webinaret');
        //Go to top of
        location.href = "#h1-important";
    }

    public render() {
        document.title = 'Konference - ' + this.props.conference.Title + ' - Tilmelding';
        //window && window.ReactNativeWebView && window.ReactNativeWebView.postMessage && window.ReactNativeWebView.postMessage('{"signup":"success"}');
        //alert("Oh, but hello");
        var heading;
        var text1;
        var text1_1;
        var text2;
        var text3;
        var button;
        var altlink;
        var buttonWebinar = <div><button type="button" data-webinarHash="lxp52tq">Register now</button></div>;

        text3 = <p>
        <br/>
        Med venlig hilsen<br/>
        Mia Skjoldstrand<br/>
        <br/>
        Konferencechef<br/>
        M: 29 41 91 81<br/>
        E: <a href="mailto:Skjoldstrand@estatemedia.dk">skjoldstrand@estatemedia.dk</a><br/>
        </p>

        if (this.props.conference.Type==3) {
            heading = <h1 id="h1-important">Tak for dit køb af webinaret <br/> '{this.props.conference.Title}'</h1>;
            text1 = <p>
                <strong>Tjek din mail!</strong><br/>Du har modtaget en mail med et link til webinaret så du kan se det med det samme.<br/><br/>
                Ønsker du at se webinaret på et senere tidspunkt, kan du altid finde det ved at gå ind på "<a href="/min-side">Min side</a>" på estatekonference.dk
                                
                <ol>
                <li>Log ind med din email og kodeord</li>
                <li>Find webinaret på listen</li>
                <li>Klik på 'Se webinaret'</li>
                </ol>Rigtig god fornøjelse!</p>;
            text2 = <p>Du vil modtage en mail med bekræftelse på dit køb af webinaret.<br/><br/>Har du nogen spørgsmål, er du velkommen til at kontakte kommerciel chef Jonas Linke på linke@estatemedia.dk <a href="mailto: linke@estatemedia.dk">linke@estatemedia.dk</a></p>;
            button = "";
            altlink = "";
        }
        else {
            heading = <h1 id="h1-important">Tak for din tilmelding til '{this.props.conference.Title}'</h1>;
            text1 = <p>Du vil modtage en e-mail med en bekræftelse på din tilmelding indenfor få minutter. (Tjek venligst eventuelt spamfilter, hvis du ikke modtager en mail fra os i din indbakke.)</p>;
            text1_1 = <p>Har du tilmeldt dig et arrangement med ’VENTELISTE’, får du besked om din eventuelle deltagelse senest syv dage før konferencen.</p>
            text2 = "";
            button = "";
            altlink = "";
        } 

        return (
            <div className="confirmation-page">
                
                {heading}
                {text1}
                {text1_1}
                {text2}
                {text3}
                {button}
            </div>
            
        )
    }
}