import "./ConferenceDownloadItem.less";
import * as React from "react";
import { Link, matchPath } from "react-router-dom";
import { ConferenceDownloadInfo } from "../../models/ConferenceDownloadInfo";
import Utils from "../../services/Utils";
import * as _ from "lodash";

interface ConferenceDownloadItemProps {
    conference: ConferenceDownloadInfo
}

export class ConferenceDownloadItem extends React.Component<ConferenceDownloadItemProps, {}> {
    public render() {
        return (
            <div className="conference-download-item">
                <div className="download-conference-card">
                    <div className="conference-info">
                        <div className="date-container">
                            <p className="conference-date">{Utils.getLongDate(this.props.conference.StartDateTime, this.props.conference.EndDateTime)}</p>
                        </div>
                        <div className="title-container">
                            <h4 className="conference-title">{this.props.conference.Title}</h4>
                            <p className="conference-subtitle">{this.props.conference.SubTitle}</p>
                        </div>
                    </div>
                    <div className="conference-image" style={{ backgroundImage: "url(" + this.props.conference.HeaderImage + ")" }}></div>
                    <div className="details-container">
                        <div className="conference-details">
                            <p className="details-title">{this.props.conference.Type === 2 ? "Seminar" : "Konference"}</p>
                            <p>{this.props.conference.City}</p>
                        </div>
                    </div>
                </div>
                <div className="download-container">
                    <div className="downloads">
                        <h4 className="download-title">Links</h4>
                        <div className="links">
                            {this.props.conference.ProgrammeLink == null || this.props.conference.ProgrammeLink.length == 0 ? null : <p><a href={this.props.conference.ProgrammeLink} target="_blank">Program</a></p>}
                            {this.props.conference.ParticipationCertificateLink == null || this.props.conference.ParticipationCertificateLink.length == 0 ? null : <p><a href={this.props.conference.ParticipationCertificateLink} target="_blank">Kursusbevis</a></p>}
                            {this.props.conference.ParticipationListLink == null || this.props.conference.ParticipationListLink.length == 0 ? null : <p><a href={this.props.conference.ParticipationListLink} target="_blank">Deltagerliste</a></p>}
                            {this.props.conference.SurveyLink == null || this.props.conference.SurveyLink.length == 0 ? null : <p><a href={this.props.conference.SurveyLink} target="_blank">Evaluering</a></p>}

                            
                            {this.props.conference.Link == null || this.props.conference.Link.length == 0 ? null : <p className="link"><a href={this.props.conference.Link} target="_blank">Se webinaret</a></p>}
                        </div>
                    </div>
                    <div className="uploads">
                        <h4 className="download-title">Præsentationer</h4>
                        <p className="upload-links">
                            {_.map(this.props.conference.Uploads, (upload, index) => {
                                return (
                                    <span key={upload.URL}>
                                        <a href={"backend/download.php?file=" + upload.URL} target="_blank">{upload.Name} </a>{index === this.props.conference.Uploads.length - 1 ? null : <>, &nbsp;</>}
                                    </span>
                                )
                            }
                            )}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}