import "./ConferenceSidebar.less";
import * as React from "react";
import { Conference } from "src/models/Conference";
import Utils from "../../services/Utils";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
  } from 'react-share';

import {
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EmailIcon,
  } from 'react-share';
  
interface ConferenceSidebarProps {
    conference: Conference;
}

export class ConferenceSidebar extends React.Component<ConferenceSidebarProps, {}> {
    public render() {

        //If the first word is "ONLY" only the field "Tekst i sidebar" will be shown
        var onlySidebarText = "";
        onlySidebarText = this.props.conference.SidebarContent.substring(0, 11);
        if (onlySidebarText=="<p>ONLY</p>") {
            this.props.conference.SidebarContent = this.props.conference.SidebarContent.slice(11);
        }


        var SpecialPrixeText = "";
        if (this.props.conference.Id=='336') {
            SpecialPrixeText = "Medlemmer af Bygherreforeningen";
        } else {
            SpecialPrixeText = "Medlemmer af Byggesocietetet";
        }
        


        return (
            <div className="conference-sidebar">
                <div className="share-button-container">
                    <div className="share-button">
                    <FacebookShareButton
                        url={'https://estatekonference.dk/arrangement/'+this.props.conference.Id+'/om/'}
                        quote={this.props.conference.Title}
                        className="button" 
                        >
                        <FacebookIcon
                        size={37}
                        round={false}
                        />
                    </FacebookShareButton>
                    </div>
                    <div className="share-button">
                    <LinkedinShareButton
                        url={'https://estatekonference.dk/arrangement/'+this.props.conference.Id+'/om/'}
                        className="button" 
                        >
                        <LinkedinIcon
                        size={37}
                        round={false} />
                    </LinkedinShareButton>
                    </div>
                    <div className="share-button">
                    <TwitterShareButton
                        url={'https://estatekonference.dk/arrangement/'+this.props.conference.Id+'/om/'}
                        className="button" 
                        >
                        <TwitterIcon
                        size={37}
                        round={false} />
                    </TwitterShareButton>
                    </div>
                    <div className="share-button">                         
                    <EmailShareButton
                        url={'https://estatekonference.dk/'+this.props.conference.Id+'/om/'}
                        subject={this.props.conference.Title}
                        body={'Læs mere på '}
                        className="button" 
                        >
                        <EmailIcon
                        size={37}
                        round={false} />
                    </EmailShareButton>        
                    </div>                                                
                </div>
                {
                this.props.conference.SidebarContent != null 
                && this.props.conference.SidebarContent.length > 0 
                && this.props.conference.ShowSidebarInfo==false  ? 
                <div>
                <div dangerouslySetInnerHTML={{__html: this.props.conference.SidebarContent}}></div>
                <div className="sidebar-part partners">
                    <p className="heading">Partnere</p>
                    <div className="partner-container">






                    </div>
                </div>
                {
                    this.props.conference.ShowParticipatingCompanies === false || this.props.conference.ParticipatingCompanies == null ? null :
                        <div className="sidebar-part participating-companies">
                            <p className="heading">Deltagende virksomheder</p>
                            <ul>
                                {this.props.conference.ParticipatingCompanies.map(participatingCompany => {
                                    return <li key={participatingCompany}>{participatingCompany}</li>
                                })}
                            </ul>
                        </div>
                }                 
                </div>                
                :
                    <>
                <div className="sidebar-part practical-information">
                    <p className="heading">Praktisk information</p>
                    
                    <p><b>Tid: </b>{Utils.getLongDate(this.props.conference.StartDateTime, this.props.conference.EndDateTime)}<br/><br/></p>
                    
                    <p><b>Sted:</b> {this.props.conference.Address}</p>
                    <p>{this.props.conference.ZipCode} {this.props.conference.City}</p>
                </div>
                <div className="sidebar-part price-details">
                <p className="heading">Priser</p>
                            
                            <div className="prices">
                                <div className="price">
                                    <div className="price-description"><p>Standard:</p></div>
                                    <div className="price-number"><p>{this.props.conference.PriceStandard},-</p></div>
                                </div>
                                <div className="price">
                                    <div className="price-description"><p>{SpecialPrixeText}:</p></div>
                                    <div className="price-number"><p>{this.props.conference.PriceByggesocietet},-</p></div>
                                </div>
                                <div className="price">
                                    <div className="price-description"><p>Medlemmer af Estate More Club:</p></div>
                                    <div className="price-number"><p>{this.props.conference.PriceMoreClub},-</p></div>
                                </div>
                            </div>
                            <p className="small-text">Priser er per person eks. moms</p>
                        </div>
                {this.props.conference.SidebarContent == null 
                || this.props.conference.SidebarContent.length == 0 
                ?  <div></div> :
                    <>
                    <div className="sidebar-part practical-information">
                        <p className="heading">Bemærk</p>
                        <p><div dangerouslySetInnerHTML={{__html: this.props.conference.SidebarContent}}></div></p>
                    </div>
                    </>
                }

                <div className="sidebar-part partners">
                    <p className="heading">Partnere</p>
                    <div className="partner-container">
                        {this.props.conference.Partners.map(partner => {
                            return <div key={partner.LogoUrl} className="partner"><a href={partner.Link} title={partner.Name} target="_blank"><img src={partner.LogoUrl} /></a></div>
                        })}
                    </div>
                </div>
                {
                    this.props.conference.ShowParticipatingCompanies === false || this.props.conference.ParticipatingCompanies == null ? null :
                        <div className="sidebar-part participating-companies">
                            <p className="heading">Deltagende virksomheder</p>
                            <ul>
                                {this.props.conference.ParticipatingCompanies.map(participatingCompany => {
                                    return <li key={participatingCompany}>{participatingCompany}</li>
                                })}
                            </ul>
                        </div>
                }                
                <div className="sidebar-part practical-information">
                    <p className="heading">Efteruddannelse</p>
                    <p><b>Antal lektioner til efteruddannelse</b></p>
                    <p>Konferencen giver {this.props.conference.CertificateModules} lektioner<br/><br/></p>
                    <p><b>Konference- og seminarbevis</b></p>
                    <p>Vi udsteder konference- og seminarbevis til dokumentation af deltagelse for eksempel til Dansk Ejendomsmæglerforening eller Advokaternes Efteruddannelse.</p>
                </div>

                <div className="sidebar-part practical-information">
                    <p className="heading">Byggesocietetet</p>
                    <p>Medlemskab i Byggesocietetet er altid personligt og rabat på deltagergebyr gælder kun det navngivne medlem.</p>
                    <p>Tilmeldingen er bindende.</p>
                    <p>For at fremgå på deltagerlisten skal tilmelding modtages 4 hverdage inden seminaret.</p>
                </div>
                <div className="sidebar-part practical-information">
                    <p className="heading">Salg- og leveringsbetingelser</p>
                    <p>Se salgs-og leveringsbetingelser for Estate konferencer og seminarer <a href="https://estatekonference.dk/om-os" target="_blank">her</a></p>
                </div>


                

<div className="hideThis">


                {this.props.conference.SidebarContent != null && this.props.conference.SidebarContent.length > 0 ? <div dangerouslySetInnerHTML={{__html: this.props.conference.SidebarContent}}></div> :
                    <>
                        <div className="sidebar-part practical-information">
                            <p><b>Tid:</b></p>
                            <p>{Utils.getLongDate(this.props.conference.StartDateTime, this.props.conference.EndDateTime)}</p>
                            <p><b>Sted:</b></p>
                            <p>{this.props.conference.Address}, {this.props.conference.ZipCode} {this.props.conference.City}</p>
                        </div>
                        <div className="sidebar-part price-details">
                            <p><b>Pris per person, eks. moms:</b></p>
                            <div className="prices">
                                <div className="price">
                                    <div className="price-description"><p>Standard:</p></div>
                                    <div className="price-number"><p>{this.props.conference.PriceStandard},-</p></div>
                                </div>
                                <div className="price">
                                    <div className="price-description"><p>Medlemmer af Byggesocietetet:</p></div>
                                    <div className="price-number"><p>{this.props.conference.PriceByggesocietet},-</p></div>
                                </div>
                                <div className="price">
                                    <div className="price-description"><p>Medlemmer af Estate More Club:</p></div>
                                    <div className="price-number"><p>{this.props.conference.PriceMoreClub},-</p></div>
                                </div>
                            </div>
                        </div>
                    </>
                }
  </div>     
  </>
                }
             


                <div className="sidebar-part contact-information">
                    <p className="heading">Kontakt:</p>
                    {this.props.conference.UserThreeShown == false ? null :
                        <div className="single-person">
                            <div className="contact-image elisabeth">

</div>
<div className="contact-info">
    <p>Konferencekoordinator<br/>Amalie von Spreckelsen</p>
    <p>tlf. <a href="tel:004522763849">22 76 38 49</a></p>
    <p>mail: <a href="mailto:spreckelsen@estatemedia.dk">spreckelsen@estatemedia.dk</a></p>
</div>


                        </div>
                    }
                    {this.props.conference.UserOneShown == false ? null :
                        <div className="single-person">
                            <div className="contact-image nanna">

</div>
<div className="contact-info">
    <p>Konferencechef<br/>Mia Skjoldstrand</p>
    <p>tlf. <a href="tel:004529419181">29 41 91 81</a></p>
    <p>mail: <a href="mailto:skjoldstrand@estatemedia.dk">skjoldstrand@estatemedia.dk</a></p>
</div>

                        </div>
                    }

                    {this.props.conference.UserTwoShown == false ? null :
                        <div className="single-person">

<div className="contact-image adnan">

</div>
<div className="contact-info">
    <p>Kommerciel chef<br/>Jonas Linke</p>
    <p>tlf. <a href="tel:004551538350">51 53 83 50</a></p>
    <p>mail: <a href="mailto:linke@estatemedia.dk">linke@estatemedia.dk</a></p>
</div>                            

                        </div>
                    }
                </div>


            </div>
        )
    }
}