import "./CoverImage.less";
import * as React from "react";

interface CoverImageProps {
    imageUrl?: string
}

export class CoverImage extends React.Component<CoverImageProps, {}> {
    public render() {
        const styleObject = {}
        if(this.props.imageUrl != null){
            styleObject["backgroundImage"] = "url(" + this.props.imageUrl + ")";
        }
        return (
            <div className="cover-image" style={styleObject}>
                <div className="highlight-component">
                    {this.props.children}
                </div>
            </div>
        )
    }
}