import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'
import { Layout } from "./Layout";
import ReactGA from "react-ga";

ReactGA.initialize('UA-34755281-2');
const history = createHistory()
history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

ReactDOM.render(((<Router history={history}>
    <Layout />
</Router>)), document.getElementById("app"));