import "./AdvertisingBanner.less";
import React from "react";
import * as _ from "lodash";

interface AdvertisingBannerState {
    banners: {
        banner_id: string;
        redirect_url: string;
        image_url: string;
        width: number;
        height: number;
        alt_text: string;
        accompanied_html: string;
        target: string;
        tracking_pixel: string;
        refresh_url: string;
        refresh_time: string;
        body: string;
    }[]
}

export class AdvertisingBanner extends React.Component<{}, AdvertisingBannerState>{
    constructor(props: {}) {
        super(props);
        this.state = {
            banners: []
        }
    }

    public async componentDidMount() {
        const bannerResponse = await fetch("https://servedbyadbutler.com/adserve/;ID=168342;size=0x0;setID=335667;type=json;click=CLICK_MACRO_PLACEHOLDER");
        const bannerJson = await bannerResponse.json();
        this.setState({ banners: (bannerJson as any).placements });
    }

    public render() {
        if (this.state.banners.length == 0) {
            return null;
        }
        return (
            <div className="advertising-banner">
                {_.map(this.state.banners, banner => {
                    if (banner.accompanied_html != null && banner.accompanied_html.length > 0) {
                        return <div key={banner.banner_id} dangerouslySetInnerHTML={{ __html: banner.accompanied_html }}></div>
                    } else if (banner.image_url != null && banner.image_url.length > 0) {
                        return (
                            <a key={banner.banner_id} href={banner.redirect_url}>
                                <img src={banner.image_url} />
                            </a>
                        )
                    }
                    return null;
                })}
            </div>
        )
    }
}



