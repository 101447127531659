import "./Hamburger.less";
import "./Hamburger-custom.less";
import * as React from "react";

interface HamburgerProps {
    isActive: boolean
}

export class Hamburger extends React.Component<HamburgerProps, {}> {
    public constructor(props: HamburgerProps) {
        super(props);
    }
    public render() {
        const className = this.props.isActive === true ? "hamburger hamburger--spin is-active" : "hamburger hamburger--spin";
        
        return (
            <button className={className} type="button">
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>
        )
    }
}