import "./MyPage.less";
import * as React from "react";
import { MyPageLogin } from "./MyPageLogin";
import { MyPageContent } from "./MyPageContent";

interface MyPageState {
    jwt: string;
}
export class MyPage extends React.Component<{}, MyPageState> {
    public constructor(props: {}) {
        super(props);

        this.state = {
            jwt: null
        }
    }

    public componentDidMount() {
        this.checkForJwt();
    }

    private checkForJwt() {
        const jwt = localStorage.getItem('estateVidenJWT');
        this.setState({ jwt: jwt });
    }

    private logOut() {
        localStorage.removeItem('estateVidenJWT');
        this.checkForJwt();
    }

    public render() {
        document.title = "Estate Media - Min side";
        return (
            <div className="my-page">
                <div className="header">
                    <h2>Min side</h2>
                    {this.state.jwt == null ? null : <p className="log-out-link" onClick={() => { this.logOut() }}>Log ud</p>}
                </div>
                {this.state.jwt == null ? <MyPageLogin onLogin={() => { this.checkForJwt() }} /> : <MyPageContent logOut={this.logOut.bind(this)} />}
                
            </div>
        )
    }
}