import * as React from "react";
import { Conference } from "src/models/Conference";
import Api from "../../services/Api";
import { Link, Route } from "react-router-dom";
import { EstateMoreClubSignUp } from "./EstateMoreClubSignUp";
import { EstateMoreClubInfo } from "./EstateMoreClubInfo";
import { EstateMoreClubConditions } from "./EstateMoreClubConditions";
import { EstateMoreClubConfirmation } from "./EstateMoreClubConfirmation";

export class EstateMoreClub extends React.Component<{}, {}> {
    public render() {
        document.title = "Estate More Club";
        return (
            <div className="estate-more-club">
                <h1>Estate More Club</h1>
                <Route exact path="/estate-more-club" render={(props) => <EstateMoreClubInfo />} />
                <Route path="/estate-more-club/tilmeld" render={(props) => <EstateMoreClubSignUp />} />
                <Route path="/estate-more-club/confirmation" render={(props) => <EstateMoreClubConfirmation />} />
                <Route path="/estate-more-club/betingelser" render={(props) => <EstateMoreClubConditions />} />
            </div>

        )
    }
}