import "./MyPageContent.less";
import * as React from "react";
import Api from "../../services/Api";
import { ConferenceDownloadInfo } from "../../models/ConferenceDownloadInfo";
import * as _ from "lodash";
import { Loader } from "../../components";
import { ConferenceDownloadItem } from "../../components/conferenceDownloadItem/ConferenceDownloadItem";

interface MyPageContentProps {
    logOut(): void;
}

interface MyPageContentState {
    loading: boolean;
    previousConferences: ConferenceDownloadInfo[];
    futureConferences: ConferenceDownloadInfo[];
    conferenceType: "previous" | "future";
}

export class MyPageContent extends React.Component<MyPageContentProps, MyPageContentState> {
    private MyPage: Api.MyPage;
    public constructor(props: MyPageContentProps) {
        super(props);
        this.state = {
            loading: false,
            previousConferences: null,
            futureConferences: null,
            conferenceType: "previous"
        }

        this.MyPage = new Api.MyPage();
    }

    public async componentDidMount() {
        this.setState({ loading: true });
        const conferences = await this.MyPage.getContent(0);
        this.setState({ loading: false, previousConferences: conferences.previousConferences, futureConferences: conferences.futureConferences });
    }

    private async loadMore() {
        this.setState({ loading: true });
        const conferences = await this.MyPage.getContent(this.state.previousConferences.length + this.state.futureConferences.length);

        const previousConferences = [...this.state.previousConferences];
        const futureConferences = [...this.state.futureConferences];

        if (conferences.previousConferences.length > 0) {
            previousConferences.push.apply(previousConferences, conferences.previousConferences);
        }
        if (conferences.futureConferences.length > 0) {
            futureConferences.push.apply(futureConferences, conferences.futureConferences);
        }

        this.setState({ loading: false, previousConferences: previousConferences, futureConferences: futureConferences });
    }

    private changeConferenceSelection(conferenceType: "previous" | "future") {
        this.setState({ conferenceType: conferenceType });
    }

    private getCnferencesForType() {
        if (this.state.conferenceType === "previous") {
            return this.state.previousConferences;
        } else {
            return this.state.futureConferences;
        }
    }

    public render() {
        if (this.state.previousConferences === null) {
            return <Loader />
        }
        return (
            <div className="my-page-content">
                <div className="logged-in-options">
                    <div className="conference-type-selector">
                        <p className={this.state.conferenceType === "previous" ? "selected" : null} onClick={() => { this.changeConferenceSelection("previous") }}>Tidligere konferencer</p>
                        <p className={this.state.conferenceType === "future" ? "selected" : null} onClick={() => { this.changeConferenceSelection("future") }}>Fremtidige konferencer</p>
                    </div>
                </div>
                <div className="conference-download-items">
                    {_.map(this.getCnferencesForType(), conference => {
                        return (
                            <ConferenceDownloadItem key={conference.Id} conference={conference} />
                        )
                    }
                    )}
                </div>
                <div className="load-more-button-container">
                    {this.state.loading ? <Loader /> : <div className="load-more-button" onClick={() => { this.loadMore() }}>Indlæs flere</div>}
                </div>
            </div>
        )
    }
}