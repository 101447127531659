import "./AboutSidebar.less";
import * as React from "react";

export class AboutSidebar extends React.Component<{}, {}> {

    public render() {
        return (
            <div className="about-sidebar">
                <h3>KontaktInfo:</h3>
                <p><b>Estate Media</b><br />
                    Østergade 24 B.1.tv.<br />
                    DK-1100 København K.<br />
                    Cvr.: 31271835
                </p>

                <p><b>Bank </b><br />
                    Danske Bank Helsingør<br />
                    Reg. 3163<br />
                    Konto: 10272920<br />
                    Swift: DABADKKK<br />
                    IBAN:DK0830000010272920
                </p>

                <p>Estate Media er en del af den nordiske mediegruppe Estate Media AS med hovedkontor i Oslo.</p>

                <p><b>E-post:</b> <a href="mailto:info@estatemedia.dk">info@estatemedia.dk</a></p>
                <p><b>Tlf.:</b> <a href="tel:29160536">29 16 05 36</a></p>

                <iframe width="300" height="225" style={{ border: 0 }}
                    src="https://www.google.com/maps/embed/v1/place?q=Estate+Media+Nordic+ApS&key=AIzaSyBFprQBfDDEhrwJ1c-qD4wJz8VDEGArpNk"></iframe>

                <div className="sidebar-part contact-information">
                    <div className="single-person">
                        <div className="contact-image adnan">
                        </div>
                        <div className="contact-info">
                            <p>Har du spørgsmål, er du velkommen til at kontakte kommerciel chef Jonas Linke på <a href="tel:004551538350">51 53 83 50</a> eller send <a href="mailto:linke@estatemedia.dk">linke@estatemedia.dk</a>
                            </p>
                        </div>
                    </div>
                    <div className="single-person">
                        <div className="contact-image kamilla">
                        </div>
                        <div className="contact-info">
                            <p>Du er også altid velkommen til at kontakte chefredaktør og partner Kamilla Sevel på <a href="tel:004542760020">42 76 00 20</a> eller send <a href="mailto:sevel@estatemedia.dk">sevel@estatemedia.dk</a></p>
                        </div>
                    </div>
                    <div className="single-person">
                        <div className="contact-image nanna">
                        </div>
                        <div className="contact-info">
                            <p>Har du spørgsmål til vores konferencer eller seminarer er du velkommen til at kontakte 
                            Konferencechef Mia Skjoldstrand  <a href="tel:004529419181">29 41 91 81</a> eller send <a href="mailto:skjoldstrand@estatemedia.dk">skjoldstrand@estatemedia.dk</a></p>
                        </div>
                    </div>                        
                    
                </div>
            </div>
        )
    }
}