import "./MyPageForgotPassword.less";
import * as React from "react";
import {Redirect} from "react-router-dom";
import Api from "../../services/Api";

interface MyPageForgotPasswordProps {
    onLogin(): void;
}

interface MyPageForgotPasswordState {
    email: string;
    redirect: boolean;
}

export class MyPageForgotPassword extends React.Component<MyPageForgotPasswordProps, MyPageForgotPasswordState> {
    private Users: Api.Users;
    public constructor(props: MyPageForgotPasswordProps) {
        super(props);
        this.state = {
            email: "",
            redirect: false
        }

        this.Users = new Api.Users();
    }

    private onChange(property: string, value: string) {
        const currentState = { ...this.state };
        currentState[property] = value;
        this.setState(currentState);
    }

    private async resetPassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();

        const resetPasswordResult = await this.Users.resetPassword(this.state.email);
        
        if (resetPasswordResult == null) {
            alert("Vi kunne ikke finde en bruger med den email");
        } else {
            alert("Et nyt password er blevet sendt til din email");
            this.setState({redirect: true});
        }
            
            
        
    }

    public render() {
        if(this.state.redirect === true){
            return <Redirect to={{pathname: "/min-side"}} />
        }
        return (
            <div>
            <div>Du modtager en email med dit nye password. Husk at tjekke din spammappe eller uønsket post.<br/><br/><br/></div>
            <div className="my-page-forgot-password">
                <form method="post" onSubmit={(event) => { this.resetPassword(event) }} >
                    <h3>Nulstil password</h3>
                    <div className="input-fields">
                        <label htmlFor="email">Email</label>
                        <input type="email" value={this.state.email} name="email" onChange={(event) => { this.onChange("email", event.target.value) }} />
                    </div>
                    <button type="submit" className="submit-button">Send</button>
                </form>
            </div >
            </div>
        )
    }
}