import "./ConferenceCard.less";
import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import { Conference } from "../../models/Conference";
import Utils from "../../services/Utils";

interface ConferenceCardProps {
    conference: Conference
}

interface ConferenceCardState {
    shouldRedirect: boolean
}

export class ConferenceCard extends React.Component<ConferenceCardProps, ConferenceCardState> {

    public constructor(props: ConferenceCardProps) {
        super(props);
        this.state = {
            shouldRedirect: false
        };
    }

    private navigateToConference() {
        this.setState({ shouldRedirect: true });
    }

    public render() {
        if(this.state.shouldRedirect === true) {
            return <Redirect to={"/arrangement/" + this.props.conference.Id + "/om"} push={true} />
        }


        var button;
        var datetext;
        if (this.props.conference.Type==3) {
            button = "Køb adgang";
            datetext = "Sendt ";
        } else {
            button = "Tilmeld";
            datetext = "";
        }        

        return (
            <div className="conference-card" onClick={() => { this.navigateToConference(); }}>
                <div className="conference-image" style={{ backgroundImage: "url(" + this.props.conference.HeaderImage + ")" }}></div>
                <div className="conference-info">
                    <div className="date-container">
                        <p className="conference-date">{datetext}{Utils.getLongDate(this.props.conference.StartDateTime, this.props.conference.EndDateTime)}</p>
                    </div>
                    <div className="title-container">
                        <h4 className="conference-title">{this.props.conference.Title}</h4>
                        <p className="conference-subtitle">{this.props.conference.SubTitle}</p>
                    </div>
                    <div className="details-container">
                        <div className="conference-details host-details host-city">
                            <p style={{display: "none"}}  className="details-title">{this.props.conference.Type === 2 ? "Seminar" : "Konference"}</p>
                            <p>{this.props.conference.City}</p>
                        </div>
                        <div className="conference-details host-details host-host">
                            {this.props.conference.HostLogo == null ? null : (
                                <>
                                <p className="host-title">Vært:
                                    <img className="host-logo" src={this.props.conference.HostLogo} /></p>
                                </>
                            )}
                        </div>                        
                    </div>
                </div>
                <div className="call-to-action">
                    <Link to={"/arrangement/" + this.props.conference.Id + "/tilmeld"}>
                        <p>{button}</p>
                    </Link>
                </div>
            </div>
        )
    }
}