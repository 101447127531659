import "./SignUp.less";
import * as React from "react";
import { Conference } from "src/models/Conference";
import Api from "../../services/Api";
import { Link, Redirect } from "react-router-dom";
import { AddToCalendarComponent } from "../../components/";
import * as _ from "lodash";

interface SignUpProps {
    conference: Conference;
}

enum ParticipantType {
    standard = 1,
    byggesocietetMember = 2
}

interface Participant {
    name: string;
    email: string;
    companyName: string,
    copyEmail: string;
    title: string;
    phone: string;
    invoiceName: string,
    type: ParticipantType,
    comment: string,
    receiveNewsletter: boolean
}

interface SignupData {
    participants: Participant[],
    invoiceName: string,
    invoiceAddress: string,
    invoiceZipCode: string,
    invoiceCity: string,
    country: string,
    invoiceEmail: string,
    invoiceVatNumber: string,
    invoiceEanNumber: string,
    discountCode: string,
    estateMoreClubSignup: number
}

interface SignUpState {
    signUpData: SignupData;
    shouldRedirect: boolean;
}

export class SignUp extends React.Component<SignUpProps, SignUpState> {
    private conferences: Api.Conferences;
    private emptyParticipant = {
        name: "",
        email: "",
        copyEmail: "",
        companyName: "",
        title: "",
        phone: "",
        invoiceName: "",
        type: 1,
        comment: "",
        receiveNewsletter: true
    }
    constructor(props: SignUpProps) {
        super(props);
        this.conferences = new Api.Conferences();

        this.state = {
            signUpData: {
                participants: [{...this.emptyParticipant}],
                invoiceName: "",
                invoiceAddress: "",
                invoiceZipCode: "",
                invoiceCity: "",
                country: "",
                invoiceEmail: "",
                invoiceVatNumber: "",
                invoiceEanNumber: "",
                discountCode: "",
                estateMoreClubSignup: 0
            },
            shouldRedirect: false
        }
    }

    private validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private isDataValid() {
        let isValid = true;
        let errorText = "";
        let participantNumber = 0;
        _.forEach(this.state.signUpData.participants, participant => {
            participantNumber++;

            if (participant.companyName === "") {
                isValid = false;
                errorText = 'Deltager '+participantNumber+`: Du skal skrive en navn på virksomheden`;
            }

            if (this.validateEmail(participant.email) === false) {
                isValid = false;
                errorText = 'Deltager '+participantNumber+`: Emailen er ikke gyldig`;
                //alert(`Emailen for ${participant.name} er ikke gyldig`);
            }

            if (participant.name === "") {
                isValid = false;
                errorText ='Deltager '+participantNumber+`: Du skal skrive et navn`;
            }

            if (errorText != '') {
                alert(errorText);
            }
        })
        

        if (this.state.signUpData.invoiceEmail.length > 0 && this.validateEmail(this.state.signUpData.invoiceEmail) === false) {
            isValid = false;
            alert(`Emailen til faktura er ikke gyldig`);
        }
        //Show loader button while submitting
        if (isValid!=false) {
            document.getElementById('submit').style.display='none';
            document.getElementById('submitwait').style.display='block';
        }
        return isValid;
    }

    public async submitSignUp() {
        if (this.isDataValid() === false) {
            return;
        }



        try {


            if (this.props.conference.WebinarCode!='') {
                _.forEach(this.state.signUpData.participants, participant => {
                    var firstName = participant.name.split(' ').slice(0, -1).join(' ');
                    var lastName = participant.name.split(' ').slice(-1).join(' ');
                    if (lastName == "") {
                        lastName = ".";
                    }
                    if (firstName == "") {
                        firstName = ".";
                    }
                    fetch("https://hooks.zapier.com/hooks/catch/7483329/"+this.props.conference.WebinarCode+"/?first-name="+firstName+"&last-name="+lastName+"&email-address="+participant.email+"&ID="+this.props.conference.Id);
                })                
            }            

            await this.conferences.signUpToConference({ conferenceId: this.props.conference.Id, ...this.state.signUpData });
            this.setState({ shouldRedirect: true });
        } catch (error) {
            alert("Noget gik galt. Venligst prøv igen eller kontakt os");
        }
    }

    private onUpdateParticipant(participantIndex: number, propertyName: string, value: string | boolean) {
        const signUpData = { ...this.state.signUpData };
        const participant = signUpData.participants[participantIndex];
        participant[propertyName] = value;
        signUpData.participants[participantIndex] = participant;
        this.setState({ signUpData: signUpData });
    }

    private addMoreParticipants() {
        const signUpData = { ...this.state.signUpData };
        signUpData.participants.push({ ...this.emptyParticipant });
        this.setState({ signUpData: signUpData });
    }

    private onUpdate(propertyName: string, value: string | boolean) {
        const signUpData = { ...this.state.signUpData };
        signUpData[propertyName] = value;
        this.setState({ signUpData: signUpData });
    }



    public render() {
        document.title = 'Konference - ' + this.props.conference.Title + ' - Tilmelding';
        if (this.state.shouldRedirect) {
            return <Redirect to={`/arrangement/${this.props.conference.Id}/confirmation`} />;
        }

        var heading;
        var button;
        if (this.props.conference.Type==3) {
            heading = "Køb af adgang til ";
            button = "Køb";
        } else {
            heading = "Tilmelding til ";
            button = "Tilmeld";

        }
    


        /*
        return (
            <div className="sign-up">

        <div>      <div className="participant-form">
                
                <h2>Vi har desværre problemer med vores tekniske systemer.</h2>
                Hvis du ønsker at tilmelde dig en konference bedes du sende en mail til <a href="mailto:info@estatemedia.dk">info@estatemedia.dk</a><br/><br/>
                Skriv venligst:
                <ul>
                    <li>Email</li>
                    <li>Navn</li>
                    <li>Virksomhed</li>
                    <li>Telefonnummer</li>
                </ul><br/><br/>
                Hvis du har særlige ønsker til faktureringsoplysninger kan du tilføje:<br/>
                <ul>
                <li>E-mail til faktura</li>
                <li>Kontaktperson</li>
                <li>Rabatkode</li>
                <li>Adresse</li>
                <li>Postnummer</li>
                <li>By</li>
                <li>CVR-nr.</li>
                <li>EAN-kode/projekt-/afdelingsnummer</li>
                </ul>

            </div>
</div>
</div>        
        )*/

        return (
            <div className="sign-up">
                <div className="form-header">
                    <p>{heading} {this.props.conference.Title}</p>
                </div>
                <div className="sign-up-form-container">
                    {this.state.signUpData.participants.map((participant, index) => <ParticipantForm key={index} index={index} participant={participant} onUpdate={this.onUpdateParticipant.bind(this)} />)}
                    <h4>Fakturamodtager</h4>
                    <div className="row">
                        <div className="field">
                            <label htmlFor={"invoiceEmail"}>E-mail til faktura</label>
                            <input type="text" value={this.state.signUpData.invoiceEmail} onChange={(event) => { this.onUpdate("invoiceEmail", event.target.value) }} name="invoiceEmail" placeholder="" />
                        </div>
                        <div className="field">
                            <label htmlFor={"invoiceName"}>Kontaktperson</label>
                            <input required type="text" value={this.state.signUpData.invoiceName} onChange={(event) => { this.onUpdate("invoiceName", event.target.value) }} name="invoiceName" placeholder="" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="field">
                            <label htmlFor={"discountCode"}>Rabatkode</label>
                            <input required type="text" value={this.state.signUpData.discountCode} onChange={(event) => { this.onUpdate("discountCode", event.target.value) }} name="discountCode" placeholder="" />
                        </div>
                        <div className="field">
                            <label htmlFor={"invoiceAddress"}>Adresse</label>
                            <input type="text" value={this.state.signUpData.invoiceAddress} onChange={(event) => { this.onUpdate("invoiceAddress", event.target.value) }} name="invoiceAddress" placeholder="" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="field">
                            <label htmlFor={"invoiceZipCode"}>Postnummer</label>
                            <input required type="text" value={this.state.signUpData.invoiceZipCode} onChange={(event) => { this.onUpdate("invoiceZipCode", event.target.value) }} name="invoiceZipCode" placeholder="" />
                        </div>
                        <div className="field">
                            <label htmlFor={"invoiceCity"}>By</label>
                            <input type="text" value={this.state.signUpData.invoiceCity} onChange={(event) => { this.onUpdate("invoiceCity", event.target.value) }} name="invoiceCity" placeholder="" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="field">
                            <label htmlFor={"country"}>Land</label>
                            <input required type="text" value={this.state.signUpData.country} onChange={(event) => { this.onUpdate("country", event.target.value) }} name="country" placeholder="" />
                        </div>
                        <div className="field">
                            <label htmlFor={"invoiceVatNumber"}>CVR-nr.</label>
                            <input type="text" value={this.state.signUpData.invoiceVatNumber} onChange={(event) => { this.onUpdate("invoiceVatNumber", event.target.value) }} name="invoiceVatNumber" placeholder="" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="field">
                            <label htmlFor={"invoiceEanNumber"}>EAN-kode/projekt-/afdelingsnummer</label>
                            <input required type="text" value={this.state.signUpData.invoiceEanNumber} onChange={(event) => { this.onUpdate("invoiceEanNumber", event.target.value) }} name="invoiceEanNumber" placeholder="" />
                        </div>
                        <div className="field"></div>
                    </div>

                    <div className="more-club-container" id="more-club-area">
                        <h4>Deltag i vores arrangementer til en fast årlig pris</h4>
                        <p>Du betaler et årligt medlemsgebyr og kan derefter deltage i konferencer og netværk og modtage Estate Magasin uden yderligere omkostninger.</p>
                        <div className="more-club-layout">
                            <div className="more-club-content">
                                <div className="links">
                                    <a href="/estate-more-club" target="_blank">Medlemsfordele og medlemstyper</a><br/>
                                    <a href="/estate-more-club/betingelser" target="_blank">Betingelser for Estate More Club</a>
                                </div>
                                <label htmlFor={"estateMoreClubSignup"}>Tilmeld Estate More Club</label>
                                <select value={this.state.signUpData.estateMoreClubSignup} onChange={(event) => { this.onUpdate("estateMoreClubSignup", event.target.value) }} name="estateMoreClubSignup">
                                    <option value="0">Tilmeld ikke Estate More Club</option>
                                    <option value="1">Tilmeld Estate More Club Sølv</option>
                                    <option value="2">Tilmeld Estate More Club Guld</option>
                                    <option value="3">Tilmeld Estate More Club Platin</option>
                                </select>

                            </div>
                            <div className="more-club-card">
                                <div className="more-club-logo"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="notSeen">{this.props.conference.WebinarCode}</div>
                <div className="conditions">Ved tilmelding acceptere du vores betingelser. Du kan læse betingelserne <a href="/betingelser" target="_blank" id="terms-linkx" className="conditions-linkx">her</a>.</div>
                
                
                
                <div className="bottom-buttons">
                    <AddToCalendarComponent
                        title={this.props.conference.Title}
                        description={this.props.conference.Description}
                        location={this.props.conference.Address}
                        startTime={this.props.conference.StartDateTime}
                        endTime={this.props.conference.EndDateTime}
                    />
                    <div className="form-submit-buttons">
                        <p className="secondary-button" onClick={this.addMoreParticipants.bind(this)}>Tilmeld flere deltagere</p>
                        <p id="submit" className="submit-button" onClick={() => {this.submitSignUp(); }}>{button}</p>
                        <p id="submitwait" className="submit-button_wait"> Vent...</p>
                    </div>
                </div>


            </div>
        )
        
    }
}

interface ParticipantFormProps {
    index: number;
    participant: Participant;
    onUpdate(arrayIndex: number, name: string, value: string | boolean): void;
}

interface ParticipantFormState {
    shouldDisableTypeSelector: boolean;
}

class ParticipantForm extends React.Component<ParticipantFormProps, ParticipantFormState> {
    private Users: Api.Users;
    private isFetching: boolean;
    constructor(props: ParticipantFormProps) {
        super(props);
        this.Users = new Api.Users();
        this.isFetching = false;

        this.state = {
            shouldDisableTypeSelector: false
        }
    }

    private async onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        const email = event.target.value;

        if (this.isFetching === false) {
            this.isFetching = true;
            document.getElementById("message_mc").style.display='none';
            document.getElementById("message_user").style.display='none';
            document.getElementById("more-club-area").style.display='block';
            const existingUser = await this.Users.getUser(email);
            if (existingUser != null) {
                if(existingUser.IsMoreClubMember === true){
                    //alert("Velkommen! Du er registreret som medlem af Estate More Club.");
                    document.getElementById("message_mc").style.display='block';
                    document.getElementById("more-club-area").style.display='none';

                    
                    //Removed because it did not make any sense
                    //this.setState({shouldDisableTypeSelector: true});
                    //this.props.onUpdate(this.props.index, "type", "2");
                } else {
                    //alert("Vi fandt dine oplysninger i systemet, og har udfyldt med de oplysninger vi allerede har.");
                    document.getElementById("message_user").style.display='block';
                }
                //alert("InvoiceName:"+existingUser.InvoiceName);
                if (this.props.participant.name.length === 0) {
                    this.props.onUpdate(this.props.index, "name", existingUser.Name);
                }
                if (this.props.participant.title.length === 0) {
                    this.props.onUpdate(this.props.index, "title", existingUser.Title);
                }
                if (this.props.participant.phone.length === 0) {
                    this.props.onUpdate(this.props.index, "phone", existingUser.Phone);
                }
                if (this.props.participant.companyName.length === 0) {
                    this.props.onUpdate(this.props.index, "companyName", existingUser.CompanyName);
                }  
                //if (this.props.participant.invoiceName.length === 0) {
                    //this.props.onUpdate(this.props.index, "invoiceName", existingUser.InvoiceName);
                //}                                     
                this.props.onUpdate(this.props.index, "type", existingUser.MoreClubType.toString());
            }
            this.isFetching = false;
        }
    }
    public render() {
        
        var min=4; 
        var max=500;  
        var random = Math.floor(Math.random() * (+max - +min)) + +min; 

        //this.props.participant.email = "aasa@uiui"+random+".dk";
        //this.props.participant.name = "Jacob";
        //this.props.participant.companyName = "Estate Cirkus";
        
/*
        return (
            <div>X</div>

        );
  */      
        return (
            <div className="participant-form">
                <h4>Deltager {this.props.index + 1}</h4>
                <div className="row">
                    <div className="field">
                        <label htmlFor={"email"}>E-mail til deltager*</label>
                        <input required type="email" value={this.props.participant.email} onChange={(event) => { this.props.onUpdate(this.props.index, "email", event.target.value); }} onBlur={this.onEmailChange.bind(this)} name="email" placeholder="" />
                    </div>                    
                    <div className="field">
                    </div>
                </div>

                <div className="row">
                    <div className="message" id="message_mc">
                        Velkommen! Du er registreret som medlem af Estate More Club. Vi har udfyldt med de oplysninger vi allerede har.
                    </div>
                </div> 

                <div className="row">
                    <div className="message" id="message_user">
                        Vi fandt dine oplysninger i systemet, og har udfyldt med de oplysninger vi allerede har.
                    </div>                    
                </div> 


                <div className="row">
          
                    <div className="field">
                        <label htmlFor={"name"}>Navn*</label>
                        <input type="text" value={this.props.participant.name} onChange={(event) => { this.props.onUpdate(this.props.index, "name", event.target.value) }} name="name" placeholder="" />
                    </div>
                    <div className="field">
                        <label htmlFor={"title"}>Titel</label>
                        <input type="text" value={this.props.participant.title} onChange={(event) => { this.props.onUpdate(this.props.index, "title", event.target.value) }} name="title" placeholder="" />
                    </div>                    
                </div>                
                <div className="row">
                    <div className="field">
                        <label htmlFor={"companyName"}>Virksomhed*</label>
                        <input type="text" value={this.props.participant.companyName} onChange={(event) => { this.props.onUpdate(this.props.index, "companyName", event.target.value) }} name="companyName" placeholder="" />
                    </div>
                    <div className="field">
                        <label htmlFor={"copyEmail"}>Send en kopi til email</label>
                        <input required type="email" value={this.props.participant.copyEmail} onChange={(event) => { this.props.onUpdate(this.props.index, "copyEmail", event.target.value) }} name="copyEmail" placeholder="" />
                    </div>
                </div>
                <div className="row">
                    <div className="field">
                        <label htmlFor={"phone"}>Telefonnummer</label>
                        <input type="tel" value={this.props.participant.phone} onChange={(event) => { this.props.onUpdate(this.props.index, "phone", event.target.value) }} name="phone" placeholder="" />
                    </div>
                    <div className="field">
                        <label htmlFor={"type"}>Type</label>
                        <select value={this.props.participant.type} onChange={(event) => { this.props.onUpdate(this.props.index, "type", event.target.value) }} name="type">
                            {this.state.shouldDisableTypeSelector === false ? <option value="1">Standarddeltager</option> : null}
                            <option value="2">Medlem af Byggesocietetet</option>
                        </select>
                    </div>                    
                </div>

                <div className="row">

                    <div className="field">
                        <label htmlFor={"comment"}>Bemærkning</label>
                        <input type="text" value={this.props.participant.comment} onChange={(event) => { this.props.onUpdate(this.props.index, "comment", event.target.value) }} name="comment" placeholder="" />
                    </div>
                </div>
                <div className="field">
                    <div className="field">
                        <label htmlFor={"type"}>
                            <input type="checkbox" checked={this.props.participant.receiveNewsletter} onChange={(event) => { this.props.onUpdate(this.props.index, "receiveNewsletter", event.target.checked)}} name="receiveNewsletter" />Modtag nyhedsbrevet
                        </label>
                    </div>
                </div>
            </div>
        );
        
    }
}