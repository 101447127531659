import "./EstateMoreClubInfo.less";
import * as React from "react";
import { Link } from "react-router-dom";

export class EstateMoreClubInfo extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="estate-more-club-info">
                <div className="main">
                    <table className="more-club-plans">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th><strong>SØLV</strong><br />kr. 11.995,-</th>
                                <th>&nbsp;</th>
                                <th><strong>GULD</strong><br />kr. 14.995,-</th>
                                <th>&nbsp;</th>
                                <th><strong>PLATIN</strong><br />kr. 24.995,-</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                <strong>FRI ADGANG PÅ ESTATE MEDIAS MERE END 35 DAGSKONFERENCER, SEMINARER OG WEBINARER</strong><br /> 
                                Værdi per arrangement op til kr. 4.495,-
                        </td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>
                            <tr>
                                <td><strong>DELTAGERBEVIS FOR POINTGIVENDE SEMINARER OG KONFERENCER</strong><br />
                                Godkendt af Dansk Ejendomsmæglerforening og Advokatsamfundet
                        </td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>
                            <tr>
                                <td><strong>TAG EN KOLLEGA MED ELLER GIV EN PLADS VÆK</strong><br />
                                2 gange om året - værdi op til kr. 8.990,-
                        </td>
                                <td className="minus">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="minus">&nbsp;</td>
                            </tr>
                            <tr>
                                <td><strong>TAG EN KOLLEGA MED ELLER GIV EN PLADS VÆK</strong><br />
                                4 gange om året - værdi op til kr. 17.980,-

                        </td>
                                <td className="minus">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="minus">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>
                            <tr>
                                <td><strong>GRATIS ADGANG TIL PLUSS </strong><br />
                                Personlig adgang til alle pluss-nyheder - værdi kr. 4.188,-
                                </td>
                                <td className="minus">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="minus">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                <strong>ADGANG TIL PRÆSENTATIONER FRA ALLE KONFERENCER OG SEMINARER</strong><br />
                                
                        </td>
                                <td className="minus">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="minus">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>
                            <tr>
                                <td><strong>VALGFRI DELTAGELSE PÅ EJENDOMS-, BOLIG- ELLER BYGGEDAGENE</strong><br />
                                2 dage inkl. overnatning - værdi op til kr. 13.995,-

                        </td>
                                <td className="minus">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>25 % RABAT</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>
                            <tr>
                                <td className=""><strong>PERSONLIGT ABONNEMENT PÅ ESTATE MAGASIN</strong><br />
                                Leveret på udgivelsesdagen til valgfri adresse - værdi kr. 995,-
                        </td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>
                            <tr>
                                <td><strong>ESTATE MAGASIN PRE RELEASE</strong><br /> 
                                Læs Estate Magasin digitalt ugen før udgivelse

                        </td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>
                            <tr>
                                <td><strong>ABONNEMENT PÅ SCANDINAVIAN PROPERTY MAGAZINE</strong><br /> 
                                Få tilsendt magasinet til valgfri adresse - værdi kr. 149,-


                        </td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="check">&nbsp;</td>
                            </tr>                            
                        </tbody>
                    </table>
                    <p className="exVat">Alle priser er eksklusiv moms<br/>Medlemskabet er gældende 12 måneder fra tegningstidspunkt</p>
                    <p className="exVat"></p>
                    <div className="more-club-plan platin">
                        <div className="description">
                            <div className="more-club-logo"></div>
                            <h3>Platin medlemskab</h3>
                            <p>Medlemskabet for dig, der vil have adgang til viden, netværk, konferencer og seminarer også, når du ikke har mulighed for at deltage. Med fri adgang til deltagerlister, præsentationer og adgang til online pre-release af alle magasiner, har du adgang til viden, når det passer dig. Du kan tage kollegaer med på konference, og du er garanteret plads på eksklusive Ejendomsdagene eller Boligdagene med en række fordele for din indkvartering.</p>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th><strong>PLATIN</strong><br />kr. 24.990.-</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Gratis deltagelse på alle Estate Medias
dagskonferencer</strong><br /> Værdi op til kr. 3.995.- per arrangement</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Tag en kollega med gratis 2 gange om
året</strong><br /> Værdi op til kr. 7.990.-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Giv en konferenceplads væk 2 gange om
året</strong><br /> Værdi op til kr. 7.990.-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Gratis adgang til PLUSS</strong><br /> Personlig adgang til alle pluss-nyheder, værdi kr. 4.188,-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Adgang til alle præsentationer fra konferencer eller seminarer</strong><br />
                                            Få adgang til præsentationer fra alle konferencer eller seminarer efter deltagelse</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Deltagelse på Ejendomsdagene eller Boligdagene</strong><br /> 2
dage inkl. overnatning værdi op til kr. 12.995.-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Personligt abonnement på Estate
Magasin</strong><br /> Leveret på udgivelsesdagen
til valgfri adresse værdi kr. 595,-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Estate Magasin pre release</strong><br /> Læs Estate Magasin på iPad eller PC før udgivelse
</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Abonnement på Scandinavian Property
Magazine</strong><br /> Få tilsendt magasinet til
valgfri adresse, værdi kr. 85,-</p>
<p className="exVat"><br/>Alle priser er ex. moms</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                    <div className="more-club-plan gold">
                        <div className="description">
                            <div className="more-club-logo"></div>
                            <h3>GULD MEDLEMSKAB</h3>
                            <p>Med Estate More Club Guld medlemskab kan du frit tage en kollega med til 2 arrangementer årligt, så I er ere, der får glæde af ny viden og netværk. Medlemskabet giver dig mulighed for målrettet at udnytte mulighederne for netværk, adgang til ny viden og overblik. Du får adgang til at deltage frit på de este arrangementer og få rabat på Ejendomsdagene og Boligdagene.</p>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th><strong>GULD </strong><br />kr. 14.990.-</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Gratis deltagelse på alle Estate Medias
dagskonferencer</strong><br /> Værdi op til kr. 3.995.- per arrangement</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Få adgang til alle præsentationer fra konferencer, du har deltaget på</strong><br /> Konference 3.995,-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Tag en kollega med gratis 2 gange om
året</strong><br /> Værdi op til kr. 7.990.-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Rabat på deltagelse
Ejendomsdagene eller Boligdagene</strong><br /> 2 dage inkl.
overnatning værdi op til kr. 3.249.-)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Personligt abonnement på Estate
Magasin</strong><br /> Leveret på udgivelsesdagen
til valgfri adresse værdi kr. 595,-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Estate Magasin pre release</strong><br /> Læs Estate Magasin på iPad eller PC før udgivelse
</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Abonnement på Scandinavian Property
Magazine</strong><br /> Få tilsendt magasinet til
valgfri adresse, værdi kr. 85,-</p>
<p className="exVat"><br/>Alle priser er ex. moms</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className="more-club-plan silver">
                        <div className="description">
                            <div className="more-club-logo"></div>
                            <h3>Sølv medlemskab</h3>
                            <p>Dagskonferencer, seminarer, Retail og HotCop. Et sølvmedlemskab giver dig bred adgang til både viden og netværk, og samtidig får du alle de unikke fordele, som giver dig mulighed for at være informeret og få mest muligt ud af din deltagelse på konferencer og i netværksarrangementer.</p>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th><strong>SØLV</strong><br />kr. 11.990.-</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Gratis deltagelse på alle Estate Medias
dagskonferencer</strong><br /> Værdi op til kr. 3.995.- per arrangement</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Få adgang til alle præsentationer fra konferencer, du har deltaget på</strong><br /></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Personligt abonnement på Estate
Magasin</strong><br /> Leveret på udgivelsesdagen
til valgfri adresse værdi kr. 595,-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Estate Magasin pre release</strong><br /> Læs Estate Magasin på iPad eller PC før udgivelse
</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <p><strong>Abonnement på Scandinavian Property
Magazine</strong><br /> Få tilsendt magasinet til
valgfri adresse, værdi kr. 85,-</p>
<p className="exVat"><br/>Alle priser er ex. moms</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>


                    <div className="sign-up-link-container">
                        <p className="sign-up-link"><Link to={`/estate-more-club/tilmeld`}>Tilmeld</Link></p>
                        <p className="link-to-conditions"><Link to={`/estate-more-club/betingelser`}>Se praktisk information og betingelser for Estate More Club her</Link></p>
                    </div>
                </div>
                <div className="sidebar">
                    
                    <h3>KUNDESERVICE</h3>
                    <div className="contact-information">
                        <div className="single-person">
                            <div className="contact-image jonas">
                            </div>
                            <div className="contact-info">
                                <p>Har du spørgsmål, er du velkommen til at kontakte kommerciel chef Jonas Linke på <br/><a href="tel:004551538350">51 53 83 50 </a> eller send <a href="mailto:linke@estatemedia.dk">linke@estatemedia.dk</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <p>For yderligere information eller spørgsmål omkring medlemskab i Estate More Club, er du velkommen til at kontakte Elisabeth Brahe på tlf. <a href="tel:004529160536">29 16 05 36</a> eller email <a href="mailto:info@estatemedia.dk">info@estatemedia.dk</a>.</p>

                    <p>Telefonen er åben mandag – fredag fra 09.00 - 17.00</p>

                    <div className="sign-up-link-container">
                        <p className="sign-up-link"><Link to={`/estate-more-club/tilmeld`}>Tilmeld</Link></p>
                        <p className="link-to-conditions"><Link to={`/estate-more-club/betingelser`}>Se praktisk information og betingelser for Estate More Club her</Link></p>
                    </div>
                </div>
            </div >
        )
    }
}