import "./Logo.less";
import * as React from "react";

interface logoProps{
    color?: "white" | "black"
}

export class Logo extends React.Component<logoProps, {}> {
    constructor(props: logoProps) {
        super(props);
    }
    public render() {
        return (
            <div className={this.props.color == null ? "logo black" : "logo " + this.props.color}></div>
        )
    }
}