import "./Speakers.less";
import * as React from "react";
import { Conference } from "src/models/Conference";

interface SpeakersProps {
    conference: Conference;
}

export class Speakers extends React.Component<SpeakersProps, {}> {
    public render() {

        //Create array to hide dublicates
        var temp = Array(); 
        var i = 0;
        var a = 0;
        {this.props.conference.Programme.map(programmeItem => {
                return programmeItem.Speakers.map(speaker => {
                    return (
                        temp[i++] = speaker.Name
                    )
                })
            })
        }
        //Find dublicates
        for (i=0;i<temp.length;i++) {
            for (a=0;a<temp.length;a++) {
                if (i!==a) {
                    if (temp[i] == temp[a] && temp[a]!=="none") {
                        temp[i] = "none";
                    }
                }
            }
        }

        document.title = 'Konference - ' + this.props.conference.Title + ' - Indlægsholdere';
        i=-1;

return (
    <div className="speakers">
    {this.props.conference.Programme.map(programmeItem => {
        return programmeItem.Speakers.map(speaker => {
            i++;
            return (
                <div className="speaker" key={speaker.Email}  style={{ display: temp[i] }}>
                    <h3>{speaker.Name}, {speaker.Title}, {speaker.CompanyName}</h3>
                    <div className="description">
                        <div className="speaker-image">
                            <img src={speaker.ImageUrl !== null ? speaker.ImageUrl : "/client/src/resources/images/unknown-user.png"} />
                        </div>
                        <p className="speaker-description">{speaker.LongDescription}</p>
                    </div>
                </div>
            );
        })
    })}
</div>)
/*
        return (
            <div className="speakers">
                {this.props.conference.Programme.map(programmeItem => {
                    return programmeItem.Speakers.map(speaker => {
                        i++;
                        return (
                            <div className="speaker" key={speaker.Email}  style={{ display: temp[i] }}>
                                <h3>{speaker.Name}, {speaker.Title}, {speaker.CompanyName}</h3>
                                <div className="description">
                                    <div className="speaker-image">
                                        <img src={speaker.ImageUrl !== null ? speaker.ImageUrl : "/client/src/resources/images/unknown-user.png"} />
                                    </div>
                                    <p className="speaker-description">{speaker.LongDescription}</p>
                                </div>
                            </div>
                        );
                    })
                })}
            </div>
        )
        */
    }
}