import "./NewsletterSignup.less";
import * as React from "react";

interface NewsLetterSignupProps {
    textColor: string
}

interface NewsletterSignupState {
    email: string,
}

export class NewsletterSignup extends React.Component<NewsLetterSignupProps, NewsletterSignupState> {
    public constructor(props: NewsLetterSignupProps) {
        super(props);

        this.state = {
            email: ""
        }
    }
    
    private onInput(element: HTMLInputElement) {
        this.setState({ email: element.value })
    }
    
    public render() {
        return (
            <div className="newsletter-signup">
                <p className="title" style={{ color: this.props.textColor }}>Modtag vores nyhedsmail</p>

                <div id="mc_embed_signup">
                    <form action="https://estatemedia.us12.list-manage.com/subscribe/post?u=4903b20c376eee00af3a57260&amp;id=c956021310" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate={true}>
                        <div id="mc_embed_signup_scroll">
                            <div className="mc-field-group">
                                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" value={this.state.email} onChange={(e) => { this.onInput(e.currentTarget) }} />
                            </div>
                            <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_4903b20c376eee00af3a57260_c956021310" tabIndex={-1} defaultValue="" /></div>
                            <div className="subscribe-button">
                                <input type="submit" defaultValue="Send" name="subscribe" id="mc-embedded-subscribe" className="button" />
                            </div>
                            <div id="mce-responses">
                                <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                                <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}