import "./ConferenceConditions.less";
import * as React from "react";
import { Link } from "react-router-dom";

export class ConferenceConditions extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="conference-confirmation">
                <div className="main">
                    <h1>Regler og betingelser ved tilmelding til konferencer</h1>
                    <p>For at sikre dig fremtidig adgang til præsentationer og netværk har vi skabt dit eget univers med navnet Min Side. </p>
                    <p>Med tilmeldingen bekræfter du, at vi må opbevare dit navn, firmaadresse, mail og tlf. til fremtidig brug samt registrere, hvilke konferencer, du har deltaget på. </p>
                    <p>Du bekræfter samtidig, at du får vores nyhedsbrev og bliver oprettet i appen Estate Connect, hvor deltagerne har mulighed for at kontakte hinanden. </p>
                    <p>Vi videregiver ikke dine oplysninger til 3. mand. </p>
                    <p>Du er altid velkommen til at afmelde dette, og du er velkommen til at rette henvendelse på info@estatemedia.dk for at få fuld indsigt i de oplysninger, vi har registreret, og vi sletter dem altid på din opfordring. </p>
                </div>
                <div className="sidebar">
                    <h3>KUNDESERVICE</h3>
                    <p>For yderligere information eller spørgsmål, er du velkommen til at kontakte Elisabeth Brahe på tlf. <a href="tel:004529160536">29 16 05 36</a> eller email <a href="mailto:info@estatemedia.dk">info@estatemedia.dk</a>.</p>

                    <p>Telefonen er åben mandag – fredag fra 09.00 - 17.00</p>

                </div>
            </div>
        )
    }
}