import "./ConferenceOnDemand.less";
import * as React from "react";
import { CoverImage, ConferenceCardRow, Loader } from "../../components"
import { RouteComponentProps } from 'react-router-dom';
import Api from "../../services/Api";
import * as _ from "lodash";
import { Conference } from "../../models/Conference";
import { relativeTimeRounding } from "moment";

interface ConferenceOnDemandState {
    conferences: Conference[];
}

export class ConferenceOnDemand extends React.Component<RouteComponentProps, ConferenceOnDemandState> {
    private conferences: Api.Conferences;
    private lastSearchQuery: string;
    public constructor(props: RouteComponentProps) {
        super(props);

        this.state = { conferences: [] }
        this.conferences = new Api.Conferences();
    }

    public async componentDidMount() {
        const searchQuery = this.props.match.params["searchQuery"];
        this.getConferencesOnDemant(searchQuery);
    }

    public async componentDidUpdate() {
        const searchQuery = this.props.match.params["searchQuery"];
        if (this.lastSearchQuery === searchQuery) {
            return;
        }
        this.getConferencesOnDemant(searchQuery);

    }

    private async getConferencesOnDemant(searchQuery: string) {
        let conferences;
        if (searchQuery != null) {
            conferences = await this.conferences.searchConferences(searchQuery);
        } else {
            conferences = await this.conferences.getConferencesOnDemant();
        }

        this.lastSearchQuery = searchQuery;
        if(conferences){
            this.setState({ conferences: conferences });
        }
    }

    public render() {
        document.title = "Estate On Demand";
        const searchQuery = this.props.match.params["searchQuery"];
        const allConferences = this.state.conferences;
        const chunkConferences = _.chunk(allConferences, 3);

        if (this.state.conferences.length === 0) {
            return <Loader />
        }

        return (
            <div className="conference-archive">
                <CoverImage imageUrl={'/upload/gfx/ondemand.jpg'}>
                    <div className="highligt-conference">
                        <p className="archive-header">{searchQuery == null ? "Estate On Demand" : "Dine søgeresultater"}</p>
                    </div>
                </CoverImage>
                <div className="desc">
                    <p><b>Velkommen til Estate on Demand.</b></p>
                    <p>Her har du mulighed for at få et indblik i forslaget om lagerbeskatning, et overblik over retailmarkedet eller indsigt i, hvordan man skaber et rigtig godt joint venture i ejendomsbranchen. Vi har samlet alle Estate Medias on-line produkter, og du finder her et bredt udvalg af relevante virtuelle oplæg og debatter. Portalen er en direkte indgang til hurtig og samtidig dyb viden om ejendomsmarkedet. </p>
                    <p>Klik blot nedenfor og køb adgang til et eller flere webinarer præcis som du ønsker. Efterfølgende kan de købte webinarer tilgås, når du har tid og behov, og du har altid adgang til webinarerne via din personlige indgang til Estate Medias univers ”<a href="/min-side">Min side</a>”. </p>
                    <p>Har du spørgsmål, er du velkommen til at ringe til Jonas Linke på tlf. 51 53 83 50 eller sende en mail på linke@estatemedia.dk</p>
                </div>

                {chunkConferences.map((threeConferences) => <ConferenceCardRow key={threeConferences[0].Id} conferences={threeConferences} />)}
            </div>
        )
    }
}