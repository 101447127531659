import moment from "moment";
export default class Utils {
    public static getLongDate(startTimeString: string, endTimeString?: string){
        //return startTimeString+" - "+endTimeString;
        const startTime = moment(startTimeString);
        let formattedDate = startTime.locale("da").format('dddd [den] D[.] MMMM YYYY [kl.] HH[:]mm');

        if(endTimeString != null) {
            const endTime = moment(endTimeString);
            if(startTime.diff(endTime, 'days') !== 0){
                formattedDate += endTime.locale("da").format(' - dddd [den] D[.] MMMM [kl.] HH[:]mm');   
            } else {
                formattedDate += endTime.locale("da").format(" - HH[:]mm");
            }
        }
        return this.toUpperFirstCase(formattedDate);
    }

    public static toUpperFirstCase(string: string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}