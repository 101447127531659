import "./ConferencePageFooter.less";
import * as React from "react";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ConferenceCard, ConferenceCardRow } from "../../components"
import Api from "../../services/Api";
import { Conference } from "../../models/Conference";


interface ConferencePageProps {
    conferenceId: string;
}

interface ConferencePageState {
    conferences: Conference[]
}

export class ConferencePageFooter extends React.Component<ConferencePageProps, ConferencePageState> {
    private conferences: Api.Conferences;
    public constructor(props: ConferencePageProps) {
        super(props);

        this.state = { conferences: [] }
        this.conferences = new Api.Conferences();
        
    }

    public async componentDidMount() {
        var conferences = await this.conferences.getSuggestions(this.props.conferenceId);
        this.setState({ conferences: conferences });
    }

    public render() {
        const allConferences = this.state.conferences;
        const chunkConferences = _.chunk(allConferences, 2);

        //Vis ikke tilmeld ved "Estate Media i Cannes". Den bruges kun til App 1. version
        let subscribeButtonFooter;

        subscribeButtonFooter = <Link className="call-to-action" to={"/arrangement/" + this.props.conferenceId + "/tilmeld"}>Tilmeld</Link>;

        if (this.props.conferenceId=='252') {
            subscribeButtonFooter = "";
        }

        return (
            <div className="conference-page-footer">
                <div className="links">
                    <div className="social">
                    </div>
                    <div className="internal-links">
                        <a className="secondary" href={"/backend/export/pdf/program.php?id=" + this.props.conferenceId}>Download / print program</a>
                        {subscribeButtonFooter}
                    </div>
                </div>
                {this.state.conferences.length === 0 ? null : (
                <div>
                    <br/><br/>
                    <hr/>
                    <div className="more-conferences-header"><p>Flere konferencer:</p></div>
                    <div className="conference-suggestions2">
                        {chunkConferences.slice().map((threeConferences) => <ConferenceCardRow key={threeConferences.map(conference => conference.Id).toString()} conferences={threeConferences} />)}
                    </div>
                    </div>
                )}
            </div>
        )
    }
}