import "./ConferenceCardRow.less";
import * as React from "react";
import {ConferenceCard} from "../conferenceCard/ConferenceCard";
import {Conference} from "../../models/Conference";

interface ConferenceCardRowProps {
    conferences: Conference[]
}

export class ConferenceCardRow extends React.Component<ConferenceCardRowProps, {}> {
    public render() {
        return (
            <div className="conference-card-row">
                {this.props.conferences.map(conference => <ConferenceCard key={conference.Id} conference={conference} />)}
            </div>
        )
    }
}