import "./AboutPage.less";
import * as React from "react";
import { AboutSidebar } from "./AboutSidebar";

export class AboutPage extends React.Component<{}, {}> {
    public render() {
        document.title = "Om konferencer og seminarer hos Estate Media";
        return (
            <div className="about-page">
                <div className="text">
                    <h1>Om konferencer og seminarer hos Estate Media</h1>
                    <p>Estate Medias konferencer og seminarer henvender sig til beslutningstagere og ledende medarbejdere i bygge- og ejendomsbranchen og den offentlige sektor, der vil holde sig fagligt opdateret. Konferencerne og seminarerne tilrettelægges gennem journalistisk research af emnet og faglig sparring med indlægsholderne. Vi indlægger altid en række pauser, der giver dig mulighed for at netværke med deltagere og indlægsholdere.</p>
                    <p>Estate Medias arrangementer leverer relevante indlæg fra spændende talere, kombineret med debatpaneler og panelinterviews. Det gør, at du på vores konferencer og seminarer både får konkrete informationer og samtidig får belyst emnet fra flere vinkler.</p>
                    <p>Vores konferencer henvender sig til ledere og beslutningstagere og deltagerne repræsenterer typisk virksomheder i branchens top 100.</p>
                    <p>Estate Konference arrangerer årligt cirka 20 dagskonferencer og 15 seminarer, hvor vi berører aktuelle emner inden for bygge- og ejendomsbranchen. Vores dagskonferencer afholdes i hele Danmark.</p>
                    <p>De fleste af arrangementerne er godkendt til efteruddannelsespoint hos for eksempel DE.</p>
                    <p>Hvis du vil vide mere om konferencer og seminarer er du velkommen til at ringe til Jonas Linke på tlf. 51 53 83 50 eller mail <a href="mailto:linke@estatemedia.dk">linke@estatemedia.dk</a></p>
                    <p>&nbsp;</p>
                    <p><strong>SIGNATURKONFERENCER</strong></p>
                    <p>Estate Media afholder en række årligt tilbagevendende konferencer. Den vigtigste er det helt unikke faglige forum Ejendomsdagene. Læs mere her:</p>
                    <h3>Ejendomsdagene</h3>
                    <p>Ejendomsdagene er en årlig to-dages konference for den professionelle ejendomsbranche i Danmark. Ejendomsdagene kombinerer faglig opdatering med netværksopbygning. I det faglige program sættes der fokus på aktuelle problemstillinger, gældende markedspraksis og de bedste vækst- og udviklingsmuligheder for den professionelle ejendomsbranche. Konferencen indeholder desuden underholdning, festmiddag og prisoverrækkelser, hvilket giver dig et fantastisk vindue for netværk og forretningsskabelse.</p>
                    <h3>Boligdagene</h3>
                    <p>Boligdagene er en årlig begivenhed for den professionelle ejendomsbranche i Danmark med fokus på alle aspekter af køb, salg og investering i boliger. Konferencen holdes over 2 dage med overnatning og festmiddag på det naturskønne og luksuriøse spahotel Vejlefjord og er det årlige mødested for professionelle aktører i boligmarkedet. Boligdagene giver det nødvendige overblik over køb og salg af boliger i Danmark og sætter fokus på boligmarkedets udfordringer og muligheder. Konferencen tager temperaturen på boligmarkedet både i dag og i fremtiden og ser på boliger og byer, økonomi og politik og salg og udlejning.</p>
                    <h3>HotCop</h3>
                    <p>HotCop er en årlig konference, der giver dig indblik i hotelejendomme og hotelmarkedet. Konferencen afholdes i Københavnsområdet og tiltrækker investorer, aktører og beslutningstagere.</p>
                    <h3>Retail</h3>
                    <p>Retail er en årlig konference, der giver dig indblik i hele retailmarkedet og sætter fokus på nye krav og muligheder i butiksejendomme og kontrakter. Konferencen afholdes i Københavnsområdet og tiltrækker investorer, aktører, retailere og beslutningstagere.</p>
                    <p><b>Ønsker du at vise ejendomsbranchen din virksomhed?</b><br />En lang række af konferencerne afholdes i spændende omgivelser hos aktører i bygge- og ejendomsbranchen med passende konferencefaciliteter. Det giver god mulighed for at komme rundt i branchen og besøge virksomheder, som man måske ellers ikke får indblik i. Kontakt Estate Media, hvis du ønsker at være konferencevært på en af vores konferencer og dermed få mulighed for at få besøg af beslutningstagere i bygge- og ejendomsbranchen.</p>
                    <p><b>Din mening er vigtig for os</b><br />Vi forsøger altid at finde emner, der er aktuelle og som berører beslutningstagere inden for branchen. Hvis du har ideer eller ønsker til fremtidige konferencer, er du altid velkommen til at kontakte Estate Media.</p>
                    <p><b>Om Estate Media</b><br />
                        Estate Media er Nordens ledende videns- og mediehus for bygge- og ejendomsbranchen. Estate Medias mål er at udvikle ejendomsbranchens bedste nyheds- og videnstilbud samt formidle optimale informationsløsninger til de professionelle aktører i markedet.<br /><br />
                        På print udkommer Estate Magasin 6 gange om året, online leverer Estate Media daglige nyheder på estatemedia.dk og for yderligere at bidrage til faglig opdatering, netværk og debat om branchen og markedets udvikling arrangerer Estate Media mere end 30 konferencer, seminarer og events årligt.<br /><br />
                        Estate Media er i dag Danmarks stærkeste konference- og mediebrand i ejendomsbranchen med mere end 5.000 daglige følgere af vores nyhedsbrev, over 10.000 magasinlæsere og næsten 2.000 årlige deltagere på konferencer og seminarer.<br /><br />
                        Estate Media i Danmark blev stiftet i 2008. I 2012 gik selskabet sammen med det norske mediehus, Estate Media, og blev en del af Estate familien – et nordisk mediehus med fokus på ejendomsbranchen. Samtidig introducerede Estate Media Ejendomsdagene til det danske marked og i 2017 kom Boligdagene til.
                    </p>
                    <p>Læs mere om Estate Media på <strong><a href="http://www.estatemedia.dk">her</a></strong></p>
                </div>
                <div className="sidebar">
                    <AboutSidebar />
                </div>
            </div>
        )
    }
}