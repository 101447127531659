import "./ConferenceSearch.less";
import * as React from "react";
import { Redirect } from 'react-router-dom'

interface ConferenceSearchState {
    searchText: string,
    isSearching: boolean
}

export class ConferenceSearch extends React.Component<{}, ConferenceSearchState> {
    public constructor(props: {}) {
        super(props);

        this.state = {
            searchText: "",
            isSearching: false
        }
    }

    private onSearchInput(element: HTMLInputElement) {
        this.setState({ searchText: element.value })
    }

    private submitSearch() {
        this.setState({ isSearching: true });
    }

    public render() {
        return (
            <>
                {this.state.isSearching ? <Redirect to={{pathname: "/arrangementer/" + this.state.searchText}} /> :
                    <div className="conference-search">
                        <form onSubmit={() => { this.submitSearch(); }}>
                            <input className="text-input" type="text" placeholder="Søg en konference, et seminar eller et event" value={this.state.searchText} onChange={(e) => { this.onSearchInput(e.currentTarget) }} />
                            <input className="search-button" type="submit" value="Søg" />
                        </form>
                    </div>}
            </>
        )
    }
}