import "./Footer.less";
import * as React from "react";
import { Logo } from "..";
import { NewsletterSignup } from ".."

export class Footer extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="footer">
                <div className="footer-content">
                    <div className="company-information">
                        <div className="logo-container">
                            <Logo color="white" />
                        </div>
                        <p>Østergade 24 B.1.tv. DK-1100 København K.</p>
                        <p>Telefon: <a href="tel:004529160536">+45 29 16 05 36</a> | E-mail: <a href="mailto:info@estatemedia.dk">info@estatemedia.dk</a></p>
                    </div>
                    <div className="newsletter-signup">
                        <NewsletterSignup textColor="rgb(212, 163, 81)" />
                    </div>
                </div>
            </div>
        )
    }
}