import "./EstateMoreClubSignUp.less";
import * as React from "react";
import Api from "../../services/Api";
import { Link, Redirect } from "react-router-dom";

enum ParticipantType {
    standard = 1,
    byggesocietetMember = 2
}

interface Participant {
    name: string;
    email: string;
    companyName: string,
    copyEmail: string;
    title: string;
    phone: string;
    type: ParticipantType,
    comment: string,
    receiveNewsletter: boolean
}

interface SignupData {
    participants: Participant[],
    companyName: string,
    invoiceName: string,
    invoiceAddress: string,
    invoiceZipCode: string,
    invoiceCity: string,
    country: string,
    invoiceEmail: string,
    invoiceVatNumber: string,
    invoiceEanNumber: string,
    discountCode: string,
    estateMoreClubSignup: number
}

interface EstateMoreClubSignUpState {
    signUpData: SignupData;
    shouldRedirect: boolean;
}

export class EstateMoreClubSignUp extends React.Component<{}, EstateMoreClubSignUpState> {
    private users: Api.Users;
    private emptyParticipant = {
        name: "",
        email: "",
        copyEmail: "",
        companyName: "",
        title: "",
        phone: "",
        type: 1,
        comment: "",
        receiveNewsletter: true
    }
    constructor(props: {}) {
        super(props);
        this.users = new Api.Users();

        this.state = {
            signUpData: {
                participants: [this.emptyParticipant],
                companyName: "",
                invoiceName: "",
                invoiceAddress: "",
                invoiceZipCode: "",
                invoiceCity: "",
                country: "",
                invoiceEmail: "",
                invoiceVatNumber: "",
                invoiceEanNumber: "",
                discountCode: "",
                estateMoreClubSignup: 0,
            },
            shouldRedirect: false
        }
    }

    public submitSignUp() {
        try {
            this.users.signUpForMoreClub({ ...this.state.signUpData });
            this.setState({ shouldRedirect: true });
        } catch (error) {
            alert("Noget gik galt. Venligst prøv igen eller kontakt os");
        }
    }

    private onUpdateParticipant(participantIndex: number, propertyName: string, value: string | boolean) {
        const signUpData = { ...this.state.signUpData };
        const participant = signUpData.participants[participantIndex];
        participant[propertyName] = value;
        signUpData.participants[participantIndex] = participant;
        this.setState({ signUpData: signUpData });
    }

    private onUpdate(propertyName: string, value: string | boolean) {
        const signUpData = { ...this.state.signUpData };
        signUpData[propertyName] = value;
        this.setState({ signUpData: signUpData });
    }

    public render() {
        if (this.state.shouldRedirect) {
            return <Redirect to={`/estate-more-club/confirmation`} />;
        }

        return (
            <div className="estate-more-club-sign-up">
                <div className="main">
                    <div className="form-header">
                        <p>Tilmelding til More Club</p>
                    </div>
                    <div className="sign-up-form-container">
                        {this.state.signUpData.participants.map((participant, index) => <ParticipantForm key={index} index={index} participant={participant} onUpdate={this.onUpdateParticipant.bind(this)} />)}
                        <h4>Fakturamodtager</h4>
                        <div className="row">
                            <div className="field">
                                <label htmlFor={"companyName"}>Firmanavn</label>
                                <input type="text" value={this.state.signUpData.companyName} onChange={(event) => { this.onUpdate("companyName", event.target.value) }} name="companyName" placeholder="" />
                            </div>
                            <div className="field">
                                <label htmlFor={"invoiceName"}>Kontaktperson</label>
                                <input required type="text" value={this.state.signUpData.invoiceName} onChange={(event) => { this.onUpdate("invoiceName", event.target.value) }} name="invoiceName" placeholder="" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="field">
                                <label htmlFor={"invoiceEmail"}>E-mail til faktura</label>
                                <input type="text" value={this.state.signUpData.invoiceEmail} onChange={(event) => { this.onUpdate("invoiceEmail", event.target.value) }} name="invoiceEmail" placeholder="" />
                            </div>
                            <div className="field">
                                <label htmlFor={"discountCode"}>Rabatkode</label>
                                <input required type="text" value={this.state.signUpData.discountCode} onChange={(event) => { this.onUpdate("discountCode", event.target.value) }} name="discountCode" placeholder="" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="field">
                                <label htmlFor={"invoiceAddress"}>Adresse</label>
                                <input type="text" value={this.state.signUpData.invoiceAddress} onChange={(event) => { this.onUpdate("invoiceAddress", event.target.value) }} name="invoiceAddress" placeholder="" />
                            </div>
                            <div className="field">
                                <label htmlFor={"invoiceZipCode"}>Postnummer</label>
                                <input required type="text" value={this.state.signUpData.invoiceZipCode} onChange={(event) => { this.onUpdate("invoiceZipCode", event.target.value) }} name="invoiceZipCode" placeholder="" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="field">
                                <label htmlFor={"invoiceCity"}>By</label>
                                <input type="text" value={this.state.signUpData.invoiceCity} onChange={(event) => { this.onUpdate("invoiceCity", event.target.value) }} name="invoiceCity" placeholder="" />
                            </div>
                            <div className="field">
                                <label htmlFor={"country"}>Land</label>
                                <input required type="text" value={this.state.signUpData.country} onChange={(event) => { this.onUpdate("country", event.target.value) }} name="country" placeholder="" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="field">
                                <label htmlFor={"invoiceVatNumber"}>CVR-nr.</label>
                                <input type="text" value={this.state.signUpData.invoiceVatNumber} onChange={(event) => { this.onUpdate("invoiceVatNumber", event.target.value) }} name="invoiceVatNumber" placeholder="" />
                            </div>
                            <div className="field">
                                <label htmlFor={"invoiceEanNumber"}>EAN-kode/projekt-/afdelingsnummer</label>
                                <input required type="text" value={this.state.signUpData.invoiceEanNumber} onChange={(event) => { this.onUpdate("invoiceEanNumber", event.target.value) }} name="invoiceEanNumber" placeholder="" />
                            </div>
                        </div>

                        <div className="more-club-container">
                            <h4>Deltag i vores konferencer til en fast årlig pris</h4>
                            <p>Du betaler et årligt medlemsgebyr og kan derefter deltage i konferencer og netværk og modtage Estate Magasin uden yderligere omkostninger.</p>
                            <div className="more-club-layout">
                                <div className="more-club-content">
                                    <div className="links">
                                        <p><Link to="#">Medlemsfordele og medlemstyper</Link></p>
                                        <p><Link to="#">Betingelser for Estate More Club</Link></p>
                                    </div>
                                    <label htmlFor={"estateMoreClubSignup"}>Tilmeld Estate More Club</label>
                                    <select value={this.state.signUpData.estateMoreClubSignup} onChange={(event) => { this.onUpdate("estateMoreClubSignup", event.target.value) }} name="estateMoreClubSignup">
                                        <option value="0">Tilmeld ikke Estate More Club</option>
                                        <option value="1">Tilmeld Estate More Club Sølv</option>
                                        <option value="2">Tilmeld Estate More Club Guld</option>
                                        <option value="3">Tilmeld Estate More Club Platin</option>
                                    </select>

                                </div>
                                <div className="more-club-card">
                                    <div className="more-club-card-image"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-submit-buttons">
                        <p className="submit-button" onClick={() => { this.submitSignUp(); }}>Tilmeld</p>
                    </div>
                </div>
                <div className="sidebar">
                    <h3>KUNDESERVICE</h3>
                    <p>For yderligere information eller spørgsmål omkring medlemskab i Estate More Club, er du velkommen til at kontakte Elisabeth Brahe på tlf. <a href="tel:004529160536">29 16 05 36</a> eller email <a href="mailto:info@estatemedia.dk">info@estatemedia.dk</a>.</p>

                    <p>Telefonen er åben mandag – fredag fra 09.00 - 17.00</p>
                </div>
            </div>
        )
    }
}

interface ParticipantFormProps {
    index: number;
    participant: Participant;
    onUpdate(arrayIndex: number, name: string, value: string | boolean): void;
}

class ParticipantForm extends React.Component<ParticipantFormProps, {}> {
    private Users: Api.Users;
    private isFetching: boolean;
    constructor(props: ParticipantFormProps) {
        super(props);
        this.Users = new Api.Users();
        this.isFetching = false;
    }

    private async onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        const email = event.target.value;

        if (this.isFetching === false) {
            this.isFetching = true;
            const existingUser = await this.Users.getUser(email);
            if (existingUser != null) {
                alert("Vi fandt dine oplysninger i systemet, og har udfyldt med de oplysninger vi allerede har");
                if (this.props.participant.name.length === 0) {
                    this.props.onUpdate(this.props.index, "name", existingUser.Name);
                }
                if (this.props.participant.title.length === 0) {
                    this.props.onUpdate(this.props.index, "title", existingUser.Title);
                }
                if (this.props.participant.phone.length === 0) {
                    this.props.onUpdate(this.props.index, "phone", existingUser.Phone);
                }
                if (this.props.participant.companyName.length === 0) {
                    this.props.onUpdate(this.props.index, "companyName", existingUser.CompanyName);
                }                  
                this.props.onUpdate(this.props.index, "type", existingUser.MoreClubType.toString());
            }
            this.isFetching = false;
        }
    }
    public render() {
        return (
            <div className="participant-form">
                <h4>Deltager {this.props.index + 1}</h4>
                <div className="row">
                    <div className="field">
                        <label htmlFor={"email"}>E-mail til deltager*</label>
                        <input required type="email" value={this.props.participant.email} onChange={(event) => { this.props.onUpdate(this.props.index, "email", event.target.value); }} onBlur={this.onEmailChange.bind(this)} name="email" placeholder="" />
                    </div>
                    <div className="field">
                    </div>
                </div>                
                <div className="row">
                    <div className="field">
                        <label htmlFor={"name"}>Navn</label>
                        <input type="text" value={this.props.participant.name} onChange={(event) => { this.props.onUpdate(this.props.index, "name", event.target.value) }} name="name" placeholder="" />
                    </div>
                    <div className="field">
                        <label htmlFor={"title"}>Titel</label>
                        <input type="text" value={this.props.participant.title} onChange={(event) => { this.props.onUpdate(this.props.index, "title", event.target.value) }} name="title" placeholder="" />
                    </div>                    
                </div>
                <div className="row">

                    <div className="field">
                        <label htmlFor={"companyName"}>Virksomhed*</label>
                        <input type="text" value={this.props.participant.companyName} onChange={(event) => { this.props.onUpdate(this.props.index, "companyName", event.target.value) }} name="companyName" placeholder="" />
                    </div>     
                    <div className="field">
                        <label htmlFor={"copyEmail"}>Send en kopi til email</label>
                        <input required type="email" value={this.props.participant.copyEmail} onChange={(event) => { this.props.onUpdate(this.props.index, "copyEmail", event.target.value) }} name="copyEmail" placeholder="" />
                    </div>                                   
                </div>
                <div className="row">
                    
                    <div className="field">
                        <label htmlFor={"phone"}>Telefonnummer</label>
                        <input type="tel" value={this.props.participant.phone} onChange={(event) => { this.props.onUpdate(this.props.index, "phone", event.target.value) }} name="phone" placeholder="" />
                    </div>
                    <div className="field">
                        <label htmlFor={"type"}>Type</label>
                        <select value={this.props.participant.type} onChange={(event) => { this.props.onUpdate(this.props.index, "type", event.target.value) }} name="type">
                            <option value="1">Standarddeltager</option>
                            <option value="2">Medlem af Byggesocietetet</option>
                        </select>
                    </div>                       
                </div>

                <div className="row">
                 
                    <div className="field">
                        <label htmlFor={"comment"}>Bemærkning</label>
                        <input type="text" value={this.props.participant.comment} onChange={(event) => { this.props.onUpdate(this.props.index, "comment", event.target.value) }} name="comment" placeholder="" />
                    </div>
                </div>
                <div className="field">
                        <label htmlFor={"type"}>
                            <input type="checkbox" checked={this.props.participant.receiveNewsletter} onChange={(event) => { this.props.onUpdate(this.props.index, "receiveNewsletter", event.target.checked) }} name="receiveNewsletter" />Modtag nyhedsbrevet
                        </label>
                    </div>
            </div>
        );
    }
}