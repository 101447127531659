import "./MyPageLogin.less";
import * as React from "react";
import Api from "../../services/Api";
import {Link} from "react-router-dom";

interface MyPageLoginProps {
    onLogin(): void;
}

interface MyPageLoginState {
    email: string;
    password: string;
}

export class MyPageLogin extends React.Component<MyPageLoginProps, MyPageLoginState> {
    private MyPage: Api.MyPage;
    public constructor(props: MyPageLoginProps) {
        super(props);
        this.state = {
            email: "",
            password: ""
        }

        this.MyPage = new Api.MyPage();
    }

    private onChange(property: string, value: string) {
        const currentState = { ...this.state };
        currentState[property] = value;
        this.setState(currentState);
    }

    private async loginOnSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();

        const loginResult = await this.MyPage.login(this.state.email, this.state.password);
        if (loginResult == null || loginResult.jwt == null) {
            alert("Vi kunne ikke finde en bruger med den email og kodeord i systemet. Prøv venligst igen.");
            return;
        }

        localStorage.setItem('estateVidenJWT', loginResult.jwt);
        this.props.onLogin();
    }

    public render() {
        return (
            <div>
            <div>
            Velkommen til log ind til ’Min side’
            <p>På `Min side´ finder du de konferencer, seminarer og webinarer, som du har deltaget i og tilmeldt dig i fremtiden. Du kan også se og downloade dine kursusbeviser, præsentationer og gense de webinarer du har købt eller været tilmeldt. 
            </p>
            <p>
            Bemærk, at du som More Club medlem kan se alle afviklede webinarer og som Platin More Club har du ydermere adgang til samtlige præsentationer fra alle konferencer, seminarer og webinarer.
            </p>
            </div>
            <div className="my-page-login">
                <form method="post" onSubmit={(event) => { this.loginOnSubmit(event) }} >
                    <h3>Log ind</h3>
                    <div className="input-fields">
                        <label htmlFor="email">Email</label>
                        <input type="email" value={this.state.email} name="email" onChange={(event) => { this.onChange("email", event.target.value) }} />
                        <label htmlFor="password">Password</label>
                        <input type="password" value={this.state.password} name="password" onChange={(event) => { this.onChange("password", event.target.value) }} />
                        <p className="forgot-password-link"><Link to="/min-side/glemt-password">Glemt password?</Link></p>
                    </div>
                    <button type="submit" className="submit-button">Log ind</button>
                </form>
            </div >
            <div className="helptext">
            <strong>Har du problemer med at logge ind?</strong><br/><br/>
•	Vi anbefaler, at du bruger Google Chrome når du besøger ”Min side”.<br/>
•	Har du mistet dit password, så tryk blot på `<a href="/min-side/glemt-password">Glemt password</a>´ så sender vi dig et nyt.<br/>
•	Hvis du bruger klip og kopier til at sætte kodeordet ind kan det i nogle tilfælde give problemer. Prøv at skrive kodeordet i stedet for.<br/>
         
            </div>      
            </div>
        )
    }
}

/*




*/