import "./ConferencePage.less";
import * as React from "react";
import { NavLink, Route, RouteComponentProps } from "react-router-dom";
import { CoverImage, Loader } from "../../components"
import { About } from "./About";
import { Programme } from "./Programme";
import { SignUp } from "./SignUp";
import { Speakers } from "./Speakers";
import { ConferenceSidebar } from "./ConferenceSidebar";
import { ConferencePageFooter } from "./ConferencePageFooter";
import {ConfirmationPage} from "./ConfirmationPage";
import Api from "../../services/Api";
import * as _ from "lodash";
import { Conference } from "../../models/Conference";
import {Link} from "react-router-dom";
import Utils from "../../services/Utils";
import { parseTwoDigitYear } from "moment";

interface ConferenceState {
    conference: Conference;
}

export class ConferencePage extends React.Component<RouteComponentProps, ConferenceState> {
    private conferences: Api.Conferences;
    public constructor(props: RouteComponentProps) {
        super(props);

        this.state = { conference: null }
        this.conferences = new Api.Conferences();
    }

    public componentDidMount() {
        this.updateConference();
    }

    public componentDidUpdate(){
        if(this.props.match.params["conferenceId"] != this.state.conference.Id){
            window.scrollTo(0, 0);
            this.updateConference();
        }
    }

    private async updateConference() {
        const conference = await this.conferences.getConference(this.props.match.params["conferenceId"]);
        if(conference != null){
            this.setState({ conference: conference });
        }
    }

    public render() {
        if (this.state.conference === null) {
            return <Loader />
        }
        document.title = 'Konference - ' + this.state.conference.Title;

        //Check if conference is in the past. If so show warning and hide subscribe buttoms
        var today = new Date();
        today.setHours(0,0,0,0);
        //Replace is because Safari does not support dateformats with '-'
        var endDateToCheck = new Date(this.state.conference.EndDateTime.replace(/-/g, "/"));

        let subscribeButtonTop;
        let subscribeButton;
        let conferenceEndedBanner; 

        var type = this.state.conference.Type;
        const divStyle = {
            color: '#ffffff'
          };

        var origin = window.location.origin + '/arrangement/'+this.state.conference.Id+'/tilmeld';
        subscribeButtonTop = <div className="call-to-action"><a style={divStyle} href={origin}>Tilmeld</a></div>;
        subscribeButton = <p><a className="conference-menu" href={origin}>Tilmeld</a></p>;   

        if (this.state.conference.Type!=3) {
            if (endDateToCheck > today) {
                subscribeButtonTop = <Link to={"/arrangement/" + this.state.conference.Id + "/tilmeld"}><p>Tilmeld</p></Link>;
                subscribeButton = <p><NavLink activeClassName='active' to={`/arrangement/${this.props.match.params['conferenceId']}/tilmeld`}>Tilmeld</NavLink></p>;
                conferenceEndedBanner = "";
            } else {
                subscribeButtonTop = "";
                subscribeButton = "";
                conferenceEndedBanner = <div className='warning'><div><p className='warning'>Konferencen er afholdt!</p><Link to={"/"}><p>Klik her for at se flere konferencer<br/><br/></p></Link></div></div>;
            }
        } else {
            subscribeButtonTop = <Link to={"/arrangement/" + this.state.conference.Id + "/tilmeld"}><p>Køb</p></Link>;
            subscribeButton = <p><a className="conference-menu" href={origin}>Køb</a></p>;;
            
            conferenceEndedBanner = 
            <div className="messagebox">
            <p className='messageheading'>Velkommen til Estate On Demand</p>
            <p className='message'>

            Køber du et webinar kan du se det, når du har tid og lyst. Kort tid efter din bestilling modtager du en mail med et direkte link til webinaret.  
            Du vil også altid kunne tilgå dine købte webinarer via ”<a href="/min-side">Min side</a>”.
            (tjek dit spamfilter, hvis du ikke har modtaget en mail fra os) 


            </p>
            </div>
            ;
        }

        //Vis ikke tilmeld ved "Estate Media i Cannes". Den bruges kun til App 1. version
        if (this.state.conference.Id=='252') {
            subscribeButtonTop = "";
            subscribeButton = "";            
        }




 



      
      
       


        return (
            <div className="conference-page">
                <CoverImage imageUrl={this.state.conference.HeaderImage}>
                    <div className="highligt-conference">
                        <h1 className="conference-page-header">
                        <p className="conference-test">{this.state.conference.Title}</p></h1>
                        <p className="conference-page-time">{Utils.getLongDate(this.state.conference.StartDateTime, this.state.conference.EndDateTime)}</p>
                        <div className="call-to-action">
                            {subscribeButtonTop}
                        </div>
                    </div>
                </CoverImage>
                <div className="conference-main">
                    <div className="main-content">

                            {conferenceEndedBanner}
                            
                        
                        <div className="conference-menu">
                            <p><NavLink activeClassName="active" to={`/arrangement/${this.props.match.params["conferenceId"]}/om`}>Om konferencen</NavLink></p>
                            <p><NavLink activeClassName="active" to={`/arrangement/${this.props.match.params["conferenceId"]}/program`}>Program</NavLink></p>
                            <p><NavLink activeClassName="active" to={`/arrangement/${this.props.match.params["conferenceId"]}/talere`}>Indlægsholdere</NavLink></p>
                            {subscribeButton}
                        
                        </div>
                        <Route exact path="/arrangement/:conferenceId" render={(props) => <About {...props} conference={this.state.conference} />} />
                        <Route path="/arrangement/:conferenceId/om" render={(props) => <About {...props} conference={this.state.conference} />} />
                        <Route path="/arrangement/:conferenceId/program" render={(props) => <Programme {...props} conference={this.state.conference} />} />
                        <Route path="/arrangement/:conferenceId/talere" render={(props) => <Speakers {...props} conference={this.state.conference} />} />
                        <Route path="/arrangement/:conferenceId/tilmeld" render={(props) => <SignUp {...props} conference={this.state.conference} />} />
                        <Route path="/arrangement/:conferenceId/confirmation" render={(props) => <ConfirmationPage {...props} conference={this.state.conference} />} />
                        {window.location.href.search("tilmeld") > -1 ? null : <ConferencePageFooter conferenceId={this.state.conference.Id} />}
                    </div>
                    <div className="sidebar">
                        <ConferenceSidebar conference={this.state.conference} />
                    </div>
                </div>
            </div>
        )
    }
}

