import { HttpService } from "./HttpService";
import { Conference } from "../models/Conference";
import { User } from "../models/User";
import { ConferencesDownload } from "../models/ConferenceDownloadInfo";

namespace Api {
    export class Conferences {
        private httpService: HttpService;
        public constructor() {
            this.httpService = new HttpService();
        }
        public getConferences() {
            return this.httpService.get<Conference[]>("conferencesonline");
        }


        public getConferencesOnDemant() {
            return this.httpService.get<Conference[]>("conferencesondemand");
        }
        
        public searchConferencesOnDemand(searchQuery?: string) {
            return this.httpService.get<Conference[]>("conferencesondemand/" + searchQuery);
        }

        public searchConferences(searchQuery?: string) {
            return this.httpService.get<Conference[]>("conferencesonline/" + searchQuery);
        }

        public getConference(id: string) {
            return this.httpService.get<Conference>("conference/" + id);
        }

        public signUpToConference(signUpData: {}) {
            return this.httpService.post<Conference>("conference/" + (signUpData as any).conferenceId + "/signup", signUpData);
        }

        public getSuggestions(conferenceId: string) {
            return this.httpService.get<Conference[]>("conferencesonline/getSuggestions/" + conferenceId);
        }
    }

    export class Users {
        private httpService: HttpService;
        public constructor() {
            this.httpService = new HttpService();
        }

        public getUser(email: string) {
            return this.httpService.get<User>("user/" + email);
        }

        public signUpForMoreClub(signUpData: {}){
            return this.httpService.post<Conference>("user/signupmoreclub", signUpData);
        } 

        public resetPassword(email: string){
            return this.httpService.get<User>("user/resetPassword/" + email);
        }

    }

    export class MyPage {
        private httpService: HttpService;
        public constructor(){
            this.httpService = new HttpService();
        }
        
        public login(email: string, password: string){
            return this.httpService.post<{jwt: string}>("myPage/login", {email: email, password: password});
        }

        public getContent(offset: number){
            return this.httpService.get<ConferencesDownload>("myPage/getContent/" + offset);
        }
    }
}
export default Api;